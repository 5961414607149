import React, { useEffect, useMemo, useState } from 'react';
import Input from '../../sub-component/Input';
import Select from '../../sub-component/Select';
import { useDispatch, useSelector } from 'react-redux';
import { getTanentProfile, getUserProfile, patchTanentProfile, patchUserProfile } from '../../../redux/thunks/UserProfile';
import Button from '../../sub-component/Button';
import UserProfileSlice from '../../../redux/slices/UserProfileSlice';
import { createResource, getResource, deleteResource } from '../../../redux/thunks/Resource';
import AvtarBadge from '../AvtarBadge';
import ProcessingIndicator from '../../common/ProcessingIndicator';
import { padding } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { companyProfileValidator, userProfileValidator } from '../../../utils/validators/ProfileValidator';
import { fullBlock } from '../../../utils/Constants';
import { ReactComponent as AlertIcon } from '../../../assets/icons/pricing/alert-triangle.svg';
import { ReactComponent as UplaodICon } from '../../../assets/icons/Views/candidate/settings/upload.svg';
import { ReactComponent as ProfilePic } from '../../../assets/icons/Views/candidate/settings/profile-pic.svg';

import DeskImage from '../../../assets/images/dashboard/fullblockCard.svg';
import { removeAllCookies, calculateTimeDifferenceInDays } from '../../../utils/utilities';


const GetImageURLFromId = ({ id }) => {
	// const imageResult = await dispatch(getResource({ id }));
	// if (imageResult.error) 'Not Found';

	const dispatch = useDispatch();
	const [resource, setResource] = useState(null);
	const [loading, setLoading] = useState(true);


	useEffect(() => {
		(async () => {
			if (!id) return;
			setLoading(true);
			const dispatched = await dispatch(getResource({ id: id }));
			setLoading(false);

			if (dispatched.error) return;

			setResource(dispatched.payload);
		})();
	}, []);

	if (loading && id) {
		return (
			<div className='w-100 h-100 d-flex align-items-center justify-content-center'>
				<ProcessingIndicator className='small-indicator' />
			</div>
		);
	}

	return (
		<AvtarBadge
			size={'custom'}
			width={120}
			height={120}
			// src={imageResult?.payload?.url}
			src={resource?.url}
		/>
	);
};

export default function CompanySettings({ isForUserprofile }) {

	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const dispatch = useDispatch();
	const [userCanNowUpdate, setUserCanNowUpdate] = useState(false);
	const [userProfileValidation, setUserProfileValidation] = useState(null);
	const [companyProfileValidation, setCompanyProfileValidation] = useState(null);

	const subscription = useSelector((state) => state.subscriptionReducer);
	const expireAt = subscription?.user_subscription.length > 0 ? subscription?.user_subscription[0]?.expires_at : null;
	const daysLeft = calculateTimeDifferenceInDays(expireAt);
	const openLeftDaysDisclaimer = sessionStorage.getItem('openLeftDaysDisclaimer');

	useEffect(() => {

		(async () => {
			if (isForUserprofile) {
				await dispatch(getUserProfile({}));
			}
			else {
				await dispatch(getTanentProfile({}));
			}
		})();

	}, []);

	const tanentProfile = useSelector((state) => state.userProfileReducer);
	const resource = useSelector((state) => state.resourceReducer);
	const auth = useSelector((state) => state.authReducer);

	const [profileDetails, setProfileDetails] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);

	const changeHandler = (event) => {
		if (event.target.files[0]) {
			setProfileDetails({ ...profileDetails, image_id: event.target.files[0] });
			setIsFilePicked(true);
			setUserCanNowUpdate(true);
		}
	};

	const onChangeValueHandle = (e) => {
		setProfileDetails({ ...profileDetails, [e.target.name]: e.target.value });
		setUserCanNowUpdate(true);
	};

	const handleUpdateProfile = async () => {

		if (isForUserprofile) {
			const validator = userProfileValidator({ ...profileDetails });
			if (!validator.isAllValid) {
				setUserProfileValidation(validator);
				return;
			}
		} else {
			const validator = companyProfileValidator({ ...profileDetails });
			if (!validator.isAllValid) {
				setCompanyProfileValidation(validator);
				return;
			}
		}

		if (isFilePicked && profileDetails?.image_id) {
			if (resource.data?.id) {
				dispatch(deleteResource({ id: resource.data.id }));
			}
			const formData = new FormData();
			formData.append('file', profileDetails?.image_id, profileDetails?.image_id.name);
			const result = await dispatch(createResource({ data: formData, signal: null }));

			if (result.error) return;


			if (isForUserprofile) {
				await dispatch(patchUserProfile({ data: { ...profileDetails, image_id: result?.payload?.id } }));
			}
			else {
				await dispatch(patchTanentProfile({ data: { ...profileDetails, image_id: result?.payload?.id } }));
			}
			setIsFilePicked(false);

		}
		else {
			if (isForUserprofile) {
				await dispatch(patchUserProfile({ data: { ...profileDetails } }));
			}
			else {
				await dispatch(patchTanentProfile({ data: { ...profileDetails } }));
			}
		}
		setUserCanNowUpdate(false);
	};

	useMemo(() => {
		if (isForUserprofile) {
			setProfileDetails({ ...tanentProfile?.userProfileData });
		}
		else {
			setProfileDetails({ ...tanentProfile?.data });
		}
	}, [tanentProfile?.data, tanentProfile?.userProfileData]);

	useEffect(() => {
		if (isForUserprofile) {
			if (userProfileValidation !== null && userProfileValidation?.isAllValid === false) {
				setUserProfileValidation(userProfileValidator({ ...profileDetails }));
			}
		} else {
			if (companyProfileValidation !== null && companyProfileValidation?.isAllValid === false) {
				setCompanyProfileValidation(companyProfileValidator({ ...profileDetails }));
			}
		}
	}, [userProfileValidation, companyProfileValidation, profileDetails]);

	const defaultFullBlockCard = () => {
		return (
			<div className='default-assessment-card-container'>
				<div className='default-assessment-illustration'>
					<img src={DeskImage} />
				</div>
				<div className='d-flex flex-column align-items-center' style={{ width: '100%', marginTop: '56px' }}>
					<span className='headline-3 dark-100'>Payment Pending!</span>
					<span className='body-2 dark-50' style={{ maxWidth: '500px' }}>Contact the owner to finalize any pending payments so that you can continue using the Testfuse.</span>
					<div style={{ width: '92px', height: '40px', marginTop: '16px' }}>
						<Button
							title={'Logout'}
							style={{ borderRadius: '24px' }}
							onClick={() => { removeAllCookies(); }}
						/>
					</div>
				</div>
			</div>
		);
	};

	if (fullBlock.includes(auth?.data?.tenant_status)) {
		return (
			<div className='dashboard-page'>
				<div className='payment-message' style={{ marginBottom: '24px' }}>
					<AlertIcon />
					<span className='body-2'>Missing billing information or failed to charge your payment method. Please fix the issue in billing settings or contact your Account Owner if you don&apos;t have the necessary permissions.</span>
				</div>
				{defaultFullBlockCard()}
			</div>
		);
	}

	return (
		<>
			{
				(tanentProfile.processing && tanentProfile.data === null)
					?
					<div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', height: '25px', width: '25px', margin: 'auto' }}>
						<div className='processing-indicator' style={{ width: '100%', height: '100%' }} />
					</div>
					:
					<>
						<div className='dashboard-page hide-scrollbar'>
							<div className='page-header'>
								<span className='headline-3 dark-100'>{isForUserprofile ? 'Personal Settings' : 'Company Settings'}</span>
							</div>
							<div className={`${(daysLeft < 6 && auth?.data?.tenant_status === 'trialing' && !openLeftDaysDisclaimer) && 'left-day-message-main-box'} page-content`} >
								<div className='company-profile-container'>
									<div className='company-profile-pic-detail'>
										<span className='subtitle-1 dark-100'>{isForUserprofile ? 'Profile Picture' : 'Company Logo'}</span>
										<div className='company-profile-pic'>
											<div style={{ width: '120px', height: '120px', position: 'relative' }}>
												{(profileDetails?.image_id) ?
													<>
														{
															profileDetails?.image_id instanceof File
																?
																<AvtarBadge
																	size={'custom'}
																	width={120}
																	height={120}
																	src={URL.createObjectURL(profileDetails?.image_id)}
																/>
																:
																<GetImageURLFromId id={profileDetails?.image_id} />
														}
													</>
													:
													<div className='profile-img'>
														{/* <img src={ProfilePic} alt='profile-pic' /> */}
														<input
															type='file'
															className='position-absolute pointer'
															style={{ left: '0px', width: '120px', height: '120px', opacity: '0' }}
															name='profile_picture'
															onChange={(e) => { changeHandler(e); }}
														/>
														<ProfilePic className='profile-img-upload-icon2' style={{ width: '64px', height: '64px' }} />
														<UplaodICon className='profile-img-upload-icon' style={{ width: '44px', height: '44px' }} />
													</div>
												}
											</div>
											<div className='d-flex flex-column' style={{ gap: '12px', marginTop: '12px' }}>
												<div className='relative pointer align-self-start'>
													<span className='w-100 pointer' style={{ padding: '12px 24px 12px 24px', borderRadius: '50px', background: '#121216', color: 'white' }}>Uplaod Profile Picture</span>
													<input
														type='file'
														className='position-absolute pointer'
														style={{ left: '150px', width: '190px', opacity: '0' }}
														name='profile_picture'
														onChange={(e) => { changeHandler(e); }}
													/>
												</div>
												<span className='body-2 dark-50 mt-2'>
													JPG, JPEG & PNG formats are allowed
												</span>
											</div>
										</div>
									</div>
									<div className='company-profile-details'>
										<span className='subtitle-1 dark-100'>{isForUserprofile ? 'Personal Details' : 'Company Details'}</span>
										<div className='company-profile-other-details'>
											{!isForUserprofile &&
												<>
													<div className='company-profile-edit-detail'>
														<Input
															varrient='primary'
															className='input body-3 primary-input'
															placeholder='ABC Technologies'
															title={'Company Name'}
															isRequired
															value={profileDetails?.company_name}
															name={'company_name'}
															inputStyle={companyProfileValidation?.isCompanyNameValid === false ? { border: '1px solid red' } : {}}
															onChange={onChangeValueHandle}
														/>
													</div>
													<div className='company-profile-edit-detail'>
														<Select
															readOnly
															className='select body-3'
															options={[{ name: 'Information Technology' }, { name: 'Multi National Company' }, { name: 'Food Company' }]}
															onSelection={(option) => { setProfileDetails({ ...profileDetails, industry: option.name }); setUserCanNowUpdate(true); }}
															selected={profileDetails?.industry}
															title={'Home Address'}
															isRequired
															placeholder={'Information Technology'}
															innerStyle={{ width: '100%' }}
														/>
													</div>
												</>
											}
											{isForUserprofile &&
												<>
													<div className='company-profile-edit-detail'>
														<Input
															varrient='primary'
															className='input body-3 primary-input'
															placeholder='John'
															title={'First Name'}
															isRequired
															value={profileDetails?.first_name}
															name={'first_name'}
															inputStyle={userProfileValidation?.isFirstNameValid === false ? { border: '1px solid red' } : {}}
															onChange={onChangeValueHandle}
														/>
													</div>
													<div className='company-profile-edit-detail'>
														<Input
															varrient='primary'
															className='input body-3 primary-input'
															placeholder='Doe'
															title={'Last Name'}
															isRequired
															value={profileDetails?.last_name}
															inputStyle={userProfileValidation?.isLastNameValid === false ? { border: '1px solid red' } : {}}
															name={'last_name'}
															onChange={onChangeValueHandle}
														/>
													</div>
													<div className='company-profile-edit-detail'>
														<Input
															disabled
															readOnly
															varrient='primary'
															className='input body-3 primary-input'
															placeholder='Email'
															title={'Email'}
															isRequired
															value={profileDetails?.email}
															name={'email'}
															onChange={onChangeValueHandle}
														/>
													</div>
													<div className='company-profile-edit-detail'>
														<Input
															varrient='primary'
															className='input body-3 primary-input'
															placeholder='Home address'
															title={'Home Address'}
															isRequired
															value={profileDetails?.home_address}
															name={'home_address'}
															onChange={onChangeValueHandle}
														/>
													</div>
												</>
											}
											<div className='company-profile-edit-detail'>
												<Input
													varrient='primary'
													className='input body-3 primary-input'
													placeholder='1588  Sycamore Fork Road'
													title={'Legal Address'}
													isRequired
													value={profileDetails?.legal_address}
													name={'legal_address'}
													onChange={onChangeValueHandle}
												/>
											</div>
											{!isForUserprofile &&
												<div className='company-profile-edit-detail'>
													<Input
														varrient='primary'
														className='input body-3 primary-input'
														placeholder='California'
														title={'State'}
														isRequired
														value={profileDetails?.state}
														name={'state'}
														onChange={onChangeValueHandle}
													/>
												</div>
											}
											<div className='company-profile-edit-detail'>
												<Select
													readOnly
													className='select body-3'
													options={[{ name: 'United States' }, { name: 'United Kingdom' }, { name: 'China' }, { name: 'Pakistan' }]}
													onSelection={(option) => { setProfileDetails({ ...profileDetails, country: option.name }); setUserCanNowUpdate(true); }}
													selected={profileDetails?.country}
													title={'Country'}
													isRequired
													placeholder={'United States'}
													innerStyle={{ width: '100%' }}
												/>
											</div>
											<div className='company-profile-edit-detail'>
												<Input
													varrient='primary'
													className='input body-3 primary-input'
													placeholder='92315'
													title={'Zip Code'}
													value={profileDetails?.zip_code}
													name={'zip_code'}
													onChange={onChangeValueHandle}
												/>
											</div>
											<div className='company-profile-edit-detail'>
												<Input
													varrient='primary'
													className='input body-3 primary-input'
													placeholder='954-235-7726'
													title={'Phone'}
													value={profileDetails?.phone}
													name={'phone'}
													onChange={onChangeValueHandle}
												/>
											</div>
											{!isForUserprofile &&
												<div className='company-profile-edit-detail'>
													<Input
														varrient='primary'
														className='input body-3 primary-input'
														placeholder='www.company.com'
														title={'Website URL'}
														value={profileDetails?.website_url}
														name={'website_url'}
														onChange={onChangeValueHandle}
													/>
												</div>
											}
										</div>
										<div className='w-100 d-flex justify-content-end' style={{ marginTop: '24px', marginBottom: '40px' }}>
											<Button
												style={{ height: '40px', width: '105px' }}
												toolTip={userCanNowUpdate ? 'Upadte details' : 'Change somthing to update details'}
												size='md'
												varrient='primary'
												title='Update'
												btn='ragular-btn'
												disabled={!userCanNowUpdate}
												processing={(tanentProfile.processing_patch_profile || resource.processing)}
												onClick={handleUpdateProfile}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

					</>
			}
		</>

	);
}
