import React, { useState, useEffect } from 'react';
import EmptyNotiImage from '../../../assets/images/dashboard/Empty-Noti.svg';
import MarkUnreadNotiImage from '../../../assets/images/dashboard/Mark-Unread-Noti.svg';
import { ReactComponent as BulletIcon } from '../../../assets/icons/components/dashboard/Ellipse 249.svg';
import ProcessingIndicator from '../../../components/common/ProcessingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { getNotification, patchMarkAllRead } from '../../../redux/thunks/Notification';
import { getFormatedDate, calculateTimeDifferenceInDays } from '../../../utils/utilities';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';


export default function Notifications() {
	const [filteredStatus, setFilteredStatus] = useState('');
	const [isReady, setIsReady] = useState(false);
	const [isRead, setIsRead] = useState();
	const notification = useSelector((state) => state.notificationReducer);
	const auth = useSelector((state) => state.authReducer);
	const subscription = useSelector((state) => state.subscriptionReducer);
	const expireAt = subscription?.user_subscription.length > 0 ? subscription?.user_subscription[0]?.expires_at : null;
	const daysLeft = calculateTimeDifferenceInDays(expireAt);
	const openLeftDaysDisclaimer = sessionStorage.getItem('openLeftDaysDisclaimer');
	const dispatch = useDispatch();
	const now = moment().format('YYYY MM DD');
	const isMobile = useMediaQuery('(max-width: 767px)');
	const isOnWebScreen = useMediaQuery('(min-width:1280px)');


	const filteredNotifications = (filteredStatus === '')
		? notification?.notificationData
		: notification?.notificationData?.filter((_notification) => { return (_notification?.status === filteredStatus); });

	const handleMarkAllRead = async () => {
		const dispatched = await dispatch(patchMarkAllRead({
			data: { status: 'READ' }
		}));
		if (!dispatched.error) {
			setIsRead(dispatched?.payload?.status);
		}
		dispatch(getNotification());
	};
	const displayReadNotification = () => {
		setFilteredStatus('READ');
	};
	const displayUnReadNotification = () => {
		setFilteredStatus('UNREAD');
	};
	const displayAllNotification = () => {
		setFilteredStatus('');
	};

	const getInitial = (eventType) => {
		const splittedString = eventType.split('_');
		return splittedString[splittedString.length - 1][0];
	};

	useEffect(() => {
		dispatch(getNotification());
		setIsReady(true);
	}, []);

	const defaultEmptyNotificationCard = () => {
		return (
			<div className='default-assessment-card-container'>
				<div className='default-notification-illustration'>
					<img src={filteredStatus === 'UNREAD' ? MarkUnreadNotiImage : EmptyNotiImage} />
				</div>
				<div className='w-100 d-flex flex-column align-items-center' style={{ marginTop: '20px' }}>
					{filteredStatus === 'UNREAD' ?
						<div className='d-flex flex-column align-items-center' style={{ gap: '6px' }}>
							<span className='headline-medium semibold black-700'>You’re All Caught Up!</span>
							<span className='body-2 black-500'>There are no unread notifications for you.</span>
						</div>
						:
						<span className='headline-medium semibold black-700'>No Notifications To Show!</span>
					}
				</div>
			</div>
		);
	};

	if (notification.loading || !isReady) {
		return <ProcessingIndicator className='medium-indicator' />;
	}

	return (
		<div className='dashboard-page ' style={{overflow: isOnWebScreen ? 'hidden' : 'visible'}}>
			<div className='page-header'>
				<div className='w-100 d-flex align-items-center justify-content-between'>
					<span className='headline-3 dark-100'>Notification</span>
					<div className='d-flex' style={{ gap: '12px' }}>
						<span className='body-2 dark-100 pointer underline' onClick={filteredStatus === '' ? displayUnReadNotification : displayAllNotification}>{filteredStatus === '' ? 'View Unread' : 'View All'}</span>
						<span className='body-2 dark-100 pointer underline' onClick={() => { handleMarkAllRead(), displayReadNotification(); }}>Mark all as read</span>
					</div>
				</div>
			</div>
			<div className={`${(daysLeft < 6 && auth?.data?.tenant_status === 'trialing' && !openLeftDaysDisclaimer) && 'left-day-message-main-box'} page-content`}>
				<div className='notification-view-container hide-scrollbar'>
					{filteredNotifications === null || !filteredNotifications?.length ? (
						<div className='default-notification-alert'>
							<span className='body-1 dark-100 text-center'>No new notifications at the moment!</span>
						</div>
					) : (
						<>	
							{filteredNotifications?.map((data, i) => {
								return (
									<div key={i} className='notification-message-card d-flex flex-row'>
										<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
											<div className='d-flex align-items-center' style={{gap:'8px'}}>
												<span >
													{
														data.status === 'UNREAD' ?
															<BulletIcon width={10} height={10} />
															: null
													}
												</span>
												<div className='profile-icon'><span className='bold'>{getInitial(data.event_type)}</span></div>
											</div>
											<span className='body-2 dark-100'>{data.message_body} </span>
										</div>
										<div className='d-flex flex-row' data-toggle='tooltip' data-placement='top' title={`${getFormatedDate(data.createdAt)}`} >
											<span className='body-2 pointer dark-50 mt-2' style={{ marginLeft: '5px' , wordBreak: moment(now).diff(moment(data.createdAt).format('YYYY MM DD'), 'days') === 0 ? 'break-all' : '' }}>
												{
													moment(now).diff(moment(data.createdAt).format('YYYY MM DD'), 'days') === 0 ? getFormatedDate(data.createdAt) : moment(now).diff(moment(data.createdAt).format('YYYY MM DD'), 'days') === 1 ? `${moment(now).diff(moment(data.createdAt).format('YYYY MM DD'), 'days')} day ago` : `${moment(now).diff(moment(data.createdAt).format('YYYY MM DD'), 'days')} days ago`
												}
											</span>
										</div>
									</div>
								);
							})}
						</>
					)}
				</div>
			</div>
		</div>


	);
}
