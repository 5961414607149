import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { subscriptionStates } from '../../views/Subscription';
// import OnboardingBox from '../OnboardingBox';
import CustomModal from '../common/Modal';
import Button from '../sub-component/Button';
import { ReactComponent as Check } from '../../assets/icons/pricing/tick.svg';
import { ReactComponent as PayIcon } from '../../assets/icons/pricing/pay-as-you-go.svg';
import { ReactComponent as StarterIcon } from '../../assets/icons/pricing/starter.svg';
import { ReactComponent as BusinessIcon } from '../../assets/icons/pricing/business.svg';
import { ReactComponent as CorporateIcon } from '../../assets/icons/pricing/corporate.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/pricing/chevron-down.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/components/common/modal/cross.svg';
import { getSubscriptionProducts } from '../../redux/thunks/Subscription';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { Spa } from '@mui/icons-material';

const Plan = (props) => {
	const isOnWebScreen = useMediaQuery('(min-width:1280px)');
	// const isOnSmallWebSCreen = useMediaQuery ('(max-width: 1440px))');
	const isOnTabletScreen = useMediaQuery('(max-width: 1200px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const { isUpdatePlan, selectedPlan, handleCloseModal, tenant_status, processing } = props;
	const dispatch = useDispatch();
	const [learnMoreP, setLearnMoreP] = useState(false);
	const [learnMoreP1, setLearnMoreP1] = useState(false);
	const [learnMoreP2, setLearnMoreP2] = useState(false);
	const [openContactUsForm, setOpenContactUsForm] = useState(false);
	const { userProfileData } = useSelector((state) => state.userProfileReducer);
	const subscription = useSelector((state) => state.subscriptionReducer);
	const hubspotMeetingsLink = `${window._env_.HUBSPOT_MEETINGS_LINK}&firstname=${encodeURIComponent(userProfileData?.first_name)}&lastname=${encodeURIComponent(userProfileData?.last_name)}&email=${encodeURIComponent(userProfileData?.email)}`;

	const handleContactUs = () => {
		setOpenContactUsForm(true);
	};

	const plans = [
		{ name: 'Lite', icon: <PayIcon />, price: '$49', details: ['Lite at $49/month with 50 attempts included', 'Additional attempts charged at $1 per attempt', 'Billing threshold: $100'] },
		{ name: 'Starter', icon: <StarterIcon />, price: '$499', details: ['Starter at $499/month with 550 attempts included', 'Additional attempts charged at $0.9 per attempt', 'Billing threshold: $1000'] },
		{ name: 'Business', icon: <BusinessIcon />, price: '$999', details: ['Business at $999/month with 1250 attempts included', 'Additional attempts charged at $0.8 per attempt.', 'Billing threshold: $2000'] },
	];

	useEffect(() => {
		dispatch(getSubscriptionProducts());
	}, []);

	return (
		<>
			{/* <OnboardingBox>
				<div className="d-flex flex-column subscription-plan-container ">

					<div className="headline-large">
						Pricing Plan
					</div>
					<div className=" d-flex align-items-center" style={{ marginTop: '10px' }}>
						<div className="hero-large  ">
							Free
						</div>
						<div className="headline-small align-self-end ms-2 " style={{ color: '#6F6C90' }}>
							25 tests
						</div>
					</div>
					<div className="planDetails" style={{ marginTop: '27px' }}>
						<div className="headline-small" style={{ fontWeight: '600' }}>
							What&apos;s more
						</div>
						<div className="d-flex align-items-start" style={{ marginTop: '16px' }}>
							<div style={{ height: '20px', width: '20px', marginRight: '8px' }}>
								<Check style={{ height: '20px', width: '20px' }} />
							</div>
							<span className="body-3 mt-1" style={{ color: '#56585A' }}>$1 for every test taken</span>
						</div>
						<div className="d-flex" style={{ marginTop: '16px' }}>
							<div style={{ height: '20px', width: '20px', marginRight: '8px' }}>
								<Check className="mr-1" style={{ height: '20px', width: '20px' }} />
							</div>
							<span className="body-3" style={{ color: '#56585A' }}>You will be billed by the end of month or once your total outstanding balance reaches $100</span>
						</div>
					</div>
					<div className="" style={{ marginTop: '40px' }}>
						<Button themeRectangleButton onClick={props.onConfirmPlan} title={'Continue'} style={{ height: '40px' }} />
					</div>
					<div className="w-100 text-center description" style={{ color: '#96999C', fontWeight: '600', marginTop: '16px' }}>
						You get 25 free tests on Sign up!
					</div>
				</div>

			</OnboardingBox> */}
			<CustomModal
				open={openContactUsForm}
				varrient='hubspot-meeting-box'
				handleCloseModal={() => { setOpenContactUsForm(false); }}
				hubspotMeetingsLink={hubspotMeetingsLink}
			/>
			<div className='payment-container'>
				{/* {(!isOnWebScreen && isUpdatePlan) &&
					<div className='w-100 d-flex flex-column align-items-start'>
						<span className='body-2 medium black-500 pointer text-start' onClick={handleCloseModal}>Billing Setting/<span className='primary-text'>Payment Plans</span></span>
					</div>
				} */}
				{(!isUpdatePlan || (!isOnWebScreen && isUpdatePlan)) ?
					<span className='w-100 text-center headline-1 dark-100'>Payment Plans</span>
					:
					<div className='w-100 d-flex flex-row align-items-start justify-content-between'>
						<span className='headline-1  text-start'>Payment Plans</span>
						<CrossIcon className='pointer' style={{ width: '44px', height: '44px' }} onClick={handleCloseModal} />
					</div>
				}
				<div className='payment-plans-container' style={{ padding: !isUpdatePlan && '0px 24px' }}>
					{plans?.map((plan, i) => {
						return (
							<div key={i} className={`payment-plan-card`} style={{height : isOnTabletScreen ? '490px' : '612px'}}>
								<div className='payment-plan-card-header'>
									<div className='d-flex flex-column' style={{ gap: '12px',marginTop:'8px' }}>
										<span className='body-1 white w-100 text-center'>{plan.name}</span>
										<span className='w-100 white text-center headline-3'><span>{plan.price}</span>/month</span>
									</div>
									<div className='w-100 d-flex align-items-center justify-content-center' style={{ position: 'relative' }}>
										<div style={{ position: 'absolute',  top: isOnTabletScreen ? '-10px' :'-10px' }}>
											{plan.icon}
										</div>
									</div>
								</div>
								<div style={{ padding: '20px' }} className='plan-items w-100'>
									<>
										{plan?.details?.map((feature, index) => {
											return (
												<span key={index} className='w-100 d-flex justify-content-between align-items-center'>
													<span className='plan-text body-4'>{feature}</span>
													<Check />
												</span>
											);
										})}
									</>
									{/* <>
									
										{!isOnMobileScreen ?
											<>
												{plan?.details?.map((feature, index) => {
													return (
														<span key={index} className='w-100 d-flex justify-content-between align-items-center'>
															<span className='plan-text body-4'>{feature}</span>
															<Check />
														</span>
													);
												})}
											</>
											:
											<div className='learn-more-container'>
												{((plan.name === 'Lite' && !learnMoreP) || (plan.name === 'Starter' && !learnMoreP1) || (plan.name === 'Business' && !learnMoreP2)) ?
													<span className='w-100 d-flex justify-content-between align-items-start'>
														<span className='plan-text body-4'>
															{plan.details[0]}
														</span>
														<Check />
													</span>
													:
													<>
														{plan.details?.map((feature, index) => {
															return (
																<span key={index} className='w-100 d-flex justify-content-between align-items-center'>
																	<span className='plan-text body-4'>{feature}</span>
																	<Check />
																</span>
															);
														})}
													</>
												}
												<div className='d-flex flex-column align-items-center justify-content-center pointer' style={{ width: '100px', gap: '6px' }} onClick={() => { plan.name === 'Lite' ? setLearnMoreP(!learnMoreP) : plan.name === 'Starter' ? setLearnMoreP1(!learnMoreP1) : plan.name === 'Business' && setLearnMoreP2(!learnMoreP2); }}>
													<span className='body-4 black-800'>{((plan.name === 'Lite' && !learnMoreP) || (plan.name === 'Starter' && !learnMoreP1) || (plan.name === 'Business' && !learnMoreP2)) ? 'Learn More' : 'View Less'}</span>
													<ArrowDownIcon style={{ transform: ((plan.name === 'Lite' && learnMoreP) || (plan.name === 'Starter' && learnMoreP1) || (plan.name === 'Business' && learnMoreP2)) && 'rotate(180deg)' }} />
												</div>
											</div>
										}
									</> */}
								</div>
								<div style={{ marginTop: 'auto', paddingBottom: '20px' }} className='w-100 d-flex justify-content-center'>
									<Button
										btn='ragular-btn'
										varrient='primary'
										disabled={plan.name === 'Lite' ? subscription?.stripe_products?.find((p) => (p.name === 'Lite' && p.id === selectedPlan?.stripe_product_id)) : plan.name === 'Starter' ? subscription?.stripe_products?.find((p) => (p.name === 'Starter' && p.id === selectedPlan?.stripe_product_id)) : plan.name === 'Business' && subscription?.stripe_products?.find((p) => (p.name === 'Business' && p.id === selectedPlan?.stripe_product_id))}
										processing={processing}
										onClick={() => props.onConfirmPlan(plan.name === 'Lite' ? { ...subscription?.stripe_products?.find((p) => p.name === 'Lite'), ...plans[0] } : plan.name === 'Starter' ? { ...subscription?.stripe_products?.find((p) => p.name === 'Starter'), ...plans[1] } : plan.name === 'Business' && { ...subscription?.stripe_products?.find((p) => p.name === 'Business'), ...plans[2] })}
										title={tenant_status === 'pre_onboard' ? 'Start 15 days Free Trial' : 'Select'}
										style={{ height: '48px', width: tenant_status === 'pre_onboard' ? '210px' : '130px' }}
									/>
								</div>
							</div>
						);
					})}
					<div className={`payment-plan-card`} style={{height : isOnTabletScreen ? '490px' : '612px'}}>
						<div className='payment-plan-card-header'>
							<div className='d-flex flex-column justify-content-between' style={{ gap: '12px' }}>

								<span className='body-1 white w-100 text-center'>{'Corporate'}</span>
								
								{isOnTabletScreen ? <span className='w-100 text-center white headline-3'>Contact for Price</span>  : <span className='w-100 text-center white headline-3'>Contact for Pricing</span> }
							</div>
							<div style={{ position: 'relative' }}>
								<div style={{ position: 'absolute', left: '-20px', top: isOnTabletScreen ? '-10px' : '-10px' }}>
									<CorporateIcon />
								</div>
							</div>
						</div>
						<div style={{ padding: '20px' }} className='plan-items w-100'>
							<span className='body-4' style={{ textAlign: 'left' }}>
								A customised corporate pricing plan for companies that require a higher testing volume. For more details or activation, kindly reach us out via Contact Us so that our sales team can guide you well.
							</span>
						</div>
						<div style={{ marginTop: 'auto', paddingBottom: '20px' }} className='w-100 d-flex justify-content-center'>
							<Button
								btn='ragular-btn'
								varrient='primary'
								title={'Contact us'}
								onClick={handleContactUs}
								style={{ height: '48px', width: '130px' }}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Plan;

Plan.propTypes = {
	onConfirmPlan: PropTypes.func
};