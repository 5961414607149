import React, { useState, useEffect } from 'react';
import Button from '../../../components/sub-component/Button';
import { Route, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { AssessmentsRoutes, PathName } from '../../../utils/routes';
import Assessments from './assesment-listing/Assessments';
import { useDispatch, useSelector } from 'react-redux';
import { createAssessSpec, listAssessSpec, listAssessSpecWithQueryParam, getAssessSpecStatsData } from '../../../redux/thunks/AssessSpec';
import TestDetails from '../test-details/TestDetails';
import { Switch } from 'react-router-dom';

// import * from './../../../assets'

export default function AdminAssessments(props) {

	/**
	 * @type {TAssessSpecState}
	 */
	
	const history = useHistory();
	const dispatch = useDispatch();
	const assessSpec = useSelector((state) => state.assessSpecReducer);
	const { routeDetails } = props;

	const [actionAssessmentModal, setActionAssessmentModal] = useState({
		isOpen: false,
		assessSpec: null,
		action: '',
	});
	const [selectedsortOption, setSelectedsortOption] = useState({
		column: 'created_at',
		order: 'DESC',
		clickCount: 0
	});
	const [searchQuery, setSearchQuery] = useState('');

	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(0);
	const itemsPerPage = 10;
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	const nextSlide = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	const prevSlide = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const handleCreateAssessSpec = async () => {
		const { payload } = await dispatch(createAssessSpec({ title: 'New Test' }));
		history.push(`${PathName.ASSESSMENTS}/create/${payload.id}`);
	};

	useEffect(() => {
		// dispatch(listAssessSpec({ query: '?limit=100' }));
		dispatch(listAssessSpecWithQueryParam({
			data: {
				page: currentPage,
				limit: itemsPerPage,
				sort_by: selectedsortOption.column,
				sort_direction: selectedsortOption.order === 'none' ? 'ASC' : selectedsortOption.order,
				status: routeDetails.pathname === '/active' ? 'ACTIVE' : routeDetails.pathname === '/draft' ? 'DRAFT' : 'ARCHIVED',
				search: searchQuery,
			}
		}));
	}, [searchQuery, selectedsortOption, currentPage, actionAssessmentModal]);

	useEffect(() => {
		dispatch(getAssessSpecStatsData({
			status: routeDetails.pathname === '/active' ? 'ACTIVE' : routeDetails.pathname === '/draft' ? 'DRAFT' : 'ARCHIVED',
		}));
	}, [routeDetails.pathname]);

	useEffect(() => {
		if (assessSpec?.data === null) return;
		setTotalItems(assessSpec?.data?.count);
	}, [assessSpec?.data]);


	return (
		<div className="assessment-view-container hide-scrollbar" style={{overflowY:'auto'}}>
			{
				assessSpec?.loading && assessSpec?.data?.results === null ? (
					<div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', height: '25px', width: '25px', margin: 'auto' }}>
						<div className="processing-indicator" style={{ width: '100%', height: '100%' }} />
					</div>
				) : (
					<Assessments
						data={assessSpec?.data?.results ? assessSpec?.data?.results?.filter((spec, _) => { return (spec?.status === routeDetails?.text?.toUpperCase()); }) : []}
						totalAssessments={assessSpec?.statsData?.stats ? assessSpec?.statsData?.stats?.all_assess_count : 0}
						activeAssessments={assessSpec?.statsData?.stats ? assessSpec?.statsData?.stats?.status_assess_count : 0}
						draftAssessments={assessSpec?.statsData?.stats ? assessSpec?.statsData?.stats?.status_assess_count : 0}
						archiveAssessments={assessSpec?.statsData?.stats ? assessSpec?.statsData?.stats?.status_assess_count : 0}
						totalInvited={assessSpec?.statsData?.stats ? assessSpec?.statsData?.stats?.invited : 0}
						totalAttempted={assessSpec?.statsData?.stats ? assessSpec?.statsData?.stats?.completed : 0}
						// activeAssessments={assessSpec?.data?.results ? assessSpec?.data?.results?.filter((spec, _) => { return (spec?.status === 'ACTIVE'); }).length : 0}
						// draftAssessments={assessSpec?.data?.results ? assessSpec?.data?.results?.filter((spec, _) => { return (spec?.status === 'DRAFT'); }).length : 0}
						// archiveAssessments={assessSpec?.data?.results ? assessSpec?.data?.results?.filter((spec, _) => { return (spec?.status === 'ARCHIVED'); }).length : 0}
						// totalInvited={assessSpec?.data?.results?.reduce((sum, obj) => {
						// 	// Check if obj.invited is a valid number before adding it to the sum
						// 	if (typeof obj.invited === 'number' && !isNaN(obj.invited)) {
						// 		return sum + obj.invited;
						// 	}
						// 	return sum; // If not a valid number, skip and keep the current sum
						// }, 0)}
						// totalAttempted={assessSpec?.data?.results?.reduce((sum, obj) => {
						// 	// Check if obj.invited is a valid number before adding it to the sum
						// 	if (typeof obj.completed === 'number' && !isNaN(obj.completed)) {
						// 		return sum + obj.completed;
						// 	}
						// 	return sum; // If not a valid number, skip and keep the current sum
						// }, 0)}
						routeText={routeDetails.text}
						variant={routeDetails.pathname === '/active' ? 'active' : routeDetails.pathname === '/draft' ? 'draft' : 'archived'}
						assessSpecProcessing={assessSpec?.processing}
						assessSpecLoading={assessSpec?.loading}
						handleCreateAssessSpec={handleCreateAssessSpec}
						setActionAssessmentModal={setActionAssessmentModal}
						actionAssessmentModal={actionAssessmentModal}
						setSelectedsortOption={setSelectedsortOption}
						selectedsortOption={selectedsortOption}
						setSearchQuery={setSearchQuery}
						searchQuery={searchQuery}
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						totalItems={totalItems}
						itemsPerPage={itemsPerPage}
						totalPages={totalPages}
						nextSlide={nextSlide}
						prevSlide={prevSlide}
					/>
				)
			}
		</div>
	);
}

// <>
// 	{
// 		Object.values(AssessmentsRoutes).map((route, index) => {
// 			return (
// 				<Route exact={route.exact} path={`${PathName.ASSESSMENTS}${route.pathname}`} key={index}>
// 					<Assessments
// 						data={assessSpec?.data || []}
// 						variant={route.pathname === '/active' ? 'active' : route.pathname === '/draft' ? 'draft' : 'archived'}
// 						routeText={route.text}
// 						assessSpecProcessing={assessSpec?.processing}
// 						handleCreateAssessSpec={handleCreateAssessSpec}
// 					/>

// 				</Route>
// 			);
// 		})
// 	}

// </>
