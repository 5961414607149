import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertBox from '../common/AlertBox';
import { patchTanentProfile } from '../../redux/thunks/UserProfile';
import Button from './../sub-component/Button';
import Input from './../sub-component/Input';
import { useHistory } from 'react-router-dom';
import { RequestStatus } from '../../utils/Constants';
// import { useLocation } from 'react-router-dom';
// import { PathName } from '../../utils/routes';



const CompanyInfoForm = () => {

	/**@type {userProfileState} **/
	const { processing_patch_profile } = useSelector(state => state.userProfileReducer);

	// const search = useLocation().search;
	// const googleOauthCode = new URLSearchParams(search).get('code');
	const dispatch = useDispatch();
	const history = useHistory();


	const [credentials, setCredentials] = useState({
		company_name: null,
	});

	const [error, setError] = useState({
		company_name: null,
	});

	const [alert, setAlert] = useState(null);

	const handleChange = (fieldName, event) => {
		const value = event.target.value;
		setCredentials({ ...credentials, [fieldName]: value });
	};

	const isFormValid = () => {
		var isValid = true;

		const _credentials = {
			company_name: { value: credentials.company_name, is_required: true },
		};

		var _error = {};
		Object.keys(_credentials).map(key => {
			if (_credentials[key].is_required && (_credentials[key].value === null || _credentials[key].value === '')) {
				_error = { ..._error, [key]: `${key.replace('_', ' ')} is required` };
				isValid = false;
			} else {
				_error = { ..._error, [key]: null };
			}
		});

		setError(_error);
		return isValid;
	};


	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!isFormValid()) {
			return;
		}

		const dispatched = await dispatch(patchTanentProfile({ data: {company_name: credentials.company_name} }));

		if (dispatched.meta.requestStatus === RequestStatus.REJECTED) {
			setAlert(
				<AlertBox description={`${dispatched.payload}!`} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
			);
			return;
		}

		// history.push('/');
		window.location.reload();
	};

	return (
		<div className="signup-input-group signup-form">
			<form onSubmit={handleSubmit}>

				<div className='alert-wrapper'>
					{alert}
				</div>

				<div className="d-flex flex-column form-input-field">
					<Input
						name={'company_name'}
						placeholder="Company Name"
						onChange={(e) => { handleChange('company_name', e); }}
						type="text" value={credentials.company_name}
						className={error.company_name ? 'error-input' : ''}
					/>
					<div className="error-text description">
						{error.company_name}
					</div>
				</div>

				<div className="submit-button">
					<Button type={'submit'} rectangle-button onClick={handleSubmit} title={'Continue'} processing={processing_patch_profile} />
				</div>
				{/* <div
					className=" button-medium-text text-center primary-text w-100 pointer"
					style={{ marginTop: '24px' }}
					onClick={() => {
						history.push('/login');
					}}
				>
					{'< Back to login'}
				</div> */}
			</form>
		</div >
	);
};
export default CompanyInfoForm;
