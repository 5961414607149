import { createSlice } from '@reduxjs/toolkit';
import { getNotification, patchNotificationStatus, patchMarkAllRead, getEmailTemplate, postEmailPreview } from '../thunks/Notification';

const NotificationSlice = createSlice({
	name: 'Notification Slice',
	initialState: {
		loading: false,
		processing: false,
		data: null,
		notificationData: [],
		current_notification: null,
		processing_patch_noti_status: false,
		error_message: null,
		success_message: null,
	},

	extraReducers: {

		[getNotification.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[getNotification.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				notificationData: payload,
			};
		},
		[getNotification.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},

		[patchNotificationStatus.pending]: (state) => {
			return {
				...state,
				processing_patch_noti_status: false,
			};
		},
		[patchNotificationStatus.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_patch_noti_status: true,
				current_notification: payload,
			};
		},
		[patchNotificationStatus.rejected]: (state) => {
			return {
				...state,
				processing_patch_noti_status: false,
			};
		},

		[postEmailPreview.pending]: (state) => {
			return {
				...state,
				processing_patch_noti_status: false,
			};
		},
		[postEmailPreview.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_patch_noti_status: true,
				current_notification: payload,
			};
		},
		[postEmailPreview.rejected]: (state) => {
			return {
				...state,
				processing_patch_noti_status: false,
			};
		},

		[patchMarkAllRead.pending]: (state) => {
			return {
				...state,
				processing_patch_noti_status: true,
			};
		},
		[patchMarkAllRead.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_patch_noti_status: false,
				current_notification: payload,
			};
		},
		[patchMarkAllRead.rejected]: (state) => {
			return {
				...state,
				processing_patch_noti_status: false,
			};
		},
		[getEmailTemplate.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[getEmailTemplate.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				notificationData: payload,
			};
		},
		[getEmailTemplate.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},


	}

});

export default NotificationSlice.reducer;