import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { DashboardRoutes, MainRoutes, PathName } from '../../utils/routes';
import { ReactComponent as Logo } from './../../assets/icons/logo-1.svg';
import { ReactComponent as MenuIcon } from './../../assets/icons/components/dashboard/menu.svg';
import { ReactComponent as BarArrowIcon } from './../../assets/icons/arrow.svg';
import logoOnMobile from '../../assets/icons/logo/testfuseLogo.svg';
import defaultImage from '../../assets/images/onboarding/profile/avatar.svg';
// // import profileAvatar1 from '../../assets/images/onboarding/profile/profile-avatar1.svg';
// import profileAvatar2 from '../../assets/images/onboarding/profile/profile-avatar2.svg';
import { ReactComponent as BellIcon } from '../../assets/icons/components/dashboard/bell.svg';
// import { ReactComponent as ProfileIcon } from '../../assets/icons/components/dashboard/profile.svg';
// import { ReactComponent as LogoutIcon } from '../../assets/icons/components/dashboard/logout.svg';
import { ReactComponent as SupportIcon } from '../../assets/icons/components/dashboard/support.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/components/dashboard/arrow-1.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/components/common/table/x.svg';
import { ReactComponent as DiscIcon } from '../../assets/icons/components/dashboard/disc.svg';
import { useMediaQuery, Avatar } from '@mui/material';
import NotificationCard from './sideMenu-card/NotificationCard';
import SupportCard from './sideMenu-card/SupportCard';
import ProfileCard from './sideMenu-card/ProfileCard';
import { UserRole, fullBlock } from '../../utils/Constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { CommonContext } from '../../utils/contexts/CommonContext';
import { getUserProfile } from '../../redux/thunks/UserProfile';
import { getResource } from '../../redux/thunks/Resource';
import AvtarBadge from './AvtarBadge';
import { useFocusWithin } from '@react-aria/interactions';
import ProcessingIndicator from '../common/ProcessingIndicator';


const MenuItem = ({ id, iconClass, plusIcon, minusIcon, title, has_nested_routes, className, isSelected, onClick, opened, style, hasUnreadNotifications }) => {
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	return (
		<>
			{title !== 'Notifications' ?
				<div className={`${className} `} onClick={() => onClick(id)} style={{marginTop:'8px'}}>
					<div className='d-flex  align-items-center' style={{width:isOnMobileScreen ? '100%' : ''}}>
						<div className='d-flex  align-items-center justify-content-center' style={{ width: '24px', height: '24px', }}>
							{typeof iconClass !== 'string' ?
								iconClass
								:
								<span className={`menu-item-icon ${iconClass} ${iconClass}-grey`} />
							}
						</div>
						<div className='side-bar-menu-icons' style={{width:isOnMobileScreen ? '100%' : ''}}>
							<div className='d-flex justify-content-between align-items-center' style={{ width: isOnMobileScreen ? '100%' :'150px' }}>
								<span className={`body-3 menu-item-text`} style={{ marginLeft: '9.8px' }}>{title}</span>
								{
									has_nested_routes &&
									<>
										{opened ? minusIcon : plusIcon}
									</>
									// <span className={`menu-item-icon plus-icon ${opened ? minusIcon : plusIcon}`} />
								}</div>
						</div>
					</div>

				</div>
				:
				<div className={`${className} `} style={style} onClick={() => onClick(id)} >
					<div className='notification df-center'>
						<BellIcon className='notification-bell-icon' style={{ marginRight: hasUnreadNotifications ? '-10px' : '0px' }} />
						{
							hasUnreadNotifications &&
							<div style={{ width: '10px', height: '10px', border: '2px solid #fff', borderRadius: '50%', backgroundColor: 'red', marginLeft: '0px', marginTop: '-10px' }}></div>
						}
						<span className='body-3 menu-item-text dark-100' style={{ marginLeft: '8px' }}>{title}</span>
					</div>
				</div>
			}
		</>
	);

};

export default function SideMenu() {


	const history = useHistory();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.authReducer);
	const { userProfileData } = useSelector((state) => state.userProfileReducer);
	const [userImage, setUserImage] = useState(null);

	const location = useLocation();

	const [selected, setSelected] = useState({
		menuItemIndex: 0,
		menuSubItemIndex: 1
	});
	const [selectedSubMenu, setSelectedSubMenu] = useState({});
	const [isMobileMenuOpen, setisMobileMenuOpen] = useState(false);
	const [show, setShow] = useState(false);
	const [open, setOpen] = useState(false);
	const [view, setView] = useState(false);
	const isOnWebScreen = useMediaQuery('(min-width:1280px)');
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const [openedMenuIndex, setOpenedMenuIndex] = useState([]);
	const [expandMenu, setExpandMenu] = useState(true);
	// const [expandMenu, setExpandMenu] = useState((auth.data.role && auth.data.role.name === UserRole.CANDIDATE) ? true : false);

	const openMenuItemLink = (index, has_nested_routes, route) => {
		if ((route?.has_nested_routes && Object.entries(route?.nested_routes)?.filter((_route) => { return ((_route[1]?.required_roles?.filter((_role) => { return (_role === auth.data.role.name); }).length > 0 && (_route.length > 0 ? _route[1]?.is_menu_item === true : false))); }).length > 0)) {
			if (openedMenuIndex.includes(index)) {
				const tempOpenedMenuIndex = openedMenuIndex.filter(_i => _i !== index);
				setOpenedMenuIndex([...tempOpenedMenuIndex]);
			}
			else {
				setOpenedMenuIndex([...openedMenuIndex, index]);
				if (fullBlock.includes(auth.data.tenant_status)) {
					return;
				}
				history.push(`${route?.pathname}${Object?.values(route?.nested_routes)[(selected.menuSubItemIndex === -1 || selected.menuSubItemIndex === 3) ? 0 : selected.menuSubItemIndex]?.pathname}`);
				setSelected({
					menuItemIndex: index,
					menuSubItemIndex: (selected.menuSubItemIndex === -1 || selected.menuSubItemIndex === 3) ? 0 : selected.menuSubItemIndex
				});
			}
		}
		else {
			if (fullBlock.includes(auth.data.tenant_status)) {
				return;
			}
			history.push(route.pathname);
			setSelected({
				menuItemIndex: index,
				menuSubItemIndex: -1
			});
		}
		setisMobileMenuOpen(((auth.data.role.name !== UserRole.CANDIDATE && route.pathname === '/dashboard/assessments') || route.pathname === '/dashboard/settings') ? true : false);
		// const isIndexOpen = openedMenuIndex.includes(index);
		// setSelectedSubMenu({});
		// if (isIndexOpen) {
		//
		// }
		// else {
		// 	setOpenedMenuIndex([...openedMenuIndex, index]);
		// }
	};

	const handleSelectedSubMenu = (id, subMenuId) => {
		setSelected({
			menuItemIndex: id,
			menuSubItemIndex: subMenuId
		});
		setisMobileMenuOpen(false);
	};

	const handleToggleProfile = () => {
		setShow(!show);
	};
	const handleToggleMsg = () => {
		setOpen(!open);
	};
	const handleToggleSupport = () => {
		setView(!view);
	};

	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: (e) => { },
		onBlurWithin: (e) => {
			setShow(false);
		}
	});

	const { commonValues, setCommonValues } = useContext(CommonContext);

	useEffect(() => {
		(async () => {
			await dispatch(getUserProfile({}));
		})();
	}, []);

	useEffect(() => {
		if (auth.data.role.name === UserRole.CANDIDATE) {
			setCommonValues({ isSideBarExpanded: false });
		}
	}, [auth.data.role.name]);

	const MAX_WIDTH = 16;
	const Name = `${userProfileData?.first_name} ${userProfileData?.last_name}`;
	function truncateName(name) {
		if (name.length > MAX_WIDTH) {
			return name.substring(0, MAX_WIDTH - 3) + '...';
		}
		return name;
	}

	useEffect(() => {
		// userProfileData?.image_id
		(async () => {
			if (userProfileData?.image_id) {
				const imageResult = await dispatch(getResource({ id: userProfileData?.image_id }));
				if (!imageResult.error) {
					setUserImage(imageResult.payload.url);
				}

				// if (dispatched.error) return;

				// setResource(dispatched.payload);
			}
		})();
	}, [userProfileData]);

	const notification = useSelector((state) => state.notificationReducer);

	const hasUnreadNotifications = useMemo(
		() => notification?.notificationData?.some((n) => n?.status === 'UNREAD'),
		[notification]
	);

	useEffect(() => {
		console.log(location, 'location');
		let menuIndex = 0;
		let subMenuIndex = 0;
		let menuRoute = null;

		if (location.pathname === MainRoutes[''].pathname) {
			menuRoute = DashboardRoutes.assesments;
			menuIndex = 0;
			subMenuIndex = 0;
		}
		else {
			Object.values(DashboardRoutes).map((route, index) => {

				if (location.pathname.includes(`${route.pathname}`)) {
					menuIndex = index;
					menuRoute = route;

					if (route.has_nested_routes) {
						Object.values(route.nested_routes).map((nested_route, i) => {
							if (location.pathname === nested_route.pathname || location.pathname.includes(`${nested_route.pathname}`)) {
								subMenuIndex = i;
							}
						});
					}

				}
			});
		}

		if ((menuRoute?.has_nested_routes && Object.entries(menuRoute?.nested_routes)?.filter((_route) => { return ((_route[1]?.required_roles?.filter((_role) => { return (_role === auth.data.role.name); }).length > 0 && (_route.length > 0 ? _route[1]?.is_menu_item === true : false))); }).length > 0)) {
			if (!openedMenuIndex.includes(menuIndex)) {
				setOpenedMenuIndex([...openedMenuIndex, menuIndex]);
				setSelected({
					menuItemIndex: menuIndex,
					menuSubItemIndex: subMenuIndex === -1 ? 0 : subMenuIndex
				});
			}
		}
		else {
			setSelected({
				menuItemIndex: menuIndex,
				menuSubItemIndex: -1
			});
		}
		handleSelectedSubMenu(menuIndex, subMenuIndex);

	}, []);


	useEffect(() => {
		console.log(commonValues.isSideBarExpanded, 'commonValues.isSideBarExpanded');
		if (isOnWebScreen && auth.data.role.name !== UserRole.CANDIDATE) {

			if (commonValues.isSideBarExpanded) {
				document.getElementById('dashboard-page-view-id').classList.add('dashboard-page-view-257');
				document.getElementById('dashboard-page-view-id').classList.remove('dashboard-page-view-75');
			}
			else {
				document.getElementById('dashboard-page-view-id').classList.add('dashboard-page-view-75');
				document.getElementById('dashboard-page-view-id').classList.remove('dashboard-page-view-257');
			}

		} else if (isOnTabletScreen && auth.data.role.name !== UserRole.CANDIDATE) {

			document.getElementById('dashboard-page-view-id').classList.add('dashboard-page-view-75');
			document.getElementById('dashboard-page-view-id').classList.remove('dashboard-page-view-257');
		}
		else {
			document.getElementById('dashboard-page-view-id').classList.remove('dashboard-page-view-75');
			document.getElementById('dashboard-page-view-id').classList.remove('dashboard-page-view-257');
		}

	}, [isOnWebScreen, isOnTabletScreen, commonValues, auth.data.role.name]);

	return (
		<>
			{
				isOnWebScreen
					?
					<div style={{ height: '100%', width: `${commonValues.isSideBarExpanded ? 'max-content' : '75px'}`, position: 'relative' }}>
						<div className={`side-menu-wrapper ${commonValues.isSideBarExpanded ? 'side-menu-open' : 'side-menu-collapsed'}`}>
							{/* <BarArrowIcon
								onClick={() => setCommonValues({ ...commonValues, isSideBarExpanded: !commonValues.isSideBarExpanded })}
								className={`pointer side-menu-open-arrow ${commonValues.isSideBarExpanded ? '' : ' side-menu-collapsed-arrow '}`}
								style={{ width: '34px', height: '34px', position: 'absolute', right: '-15px', top: '65px', zIndex: '6' }} /> */}
							<div className='side-menu'>

								<NavLink to={PathName.MAIN} className='side-menu-logo' style={{ cursor: 'pointer', textDecoration: 'none' }}>
									<Logo />
									<span style={{ color: ' #121216', fontWeight: '500', fontSize: '22px' }}>testfuse</span>
									{/* <span onClick={() => setCommonValues({ ...commonValues, isSideBarExpanded: !commonValues.isSideBarExpanded })} >
									<DiscIcon className='disc-icon' />
								</span> */}
								</NavLink>

								<div className='d-flex flex-column justify-content-between hide-scrollbar' style={{ height: 'calc(100% - 88px)' }}>
									<div className='d-flex flex-column hide-scrollbar' style={{ height: 'calc(100% - 69.5px)', overflow: 'scroll', gap: '24px' }}>

										{
											Object.values(DashboardRoutes).map((route, index) => {
												if (!route?.unvisible_for_roles?.some(item => auth.data.role.name === item) && route.is_menu_item && route.required_roles.some(item => auth.data.role.name === item)) {

													return (
														<div key={index} >
															<MenuItem
																// className={`pointer side-menu-item ${index === selected.menuItemIndex ? (route.has_nested_routes && openedMenuIndex.includes(index)) ? ' ' : ' side-menu-selected ' : ' '} ${(route.has_nested_routes && openedMenuIndex.includes(index)) ? ' ' : ' menu-item-hover-effect '}`}
																className={`pointer side-menu-item ${(index === selected.menuItemIndex && (!route.has_nested_routes || route.pathname === PathName.CANDIDATES || !openedMenuIndex.includes(index))) && ' side-menu-selected '} ${(route.has_nested_routes && openedMenuIndex.includes(index)) ? ' ' : ' menu-item-hover-effect '} ${(fullBlock.includes(auth.data.tenant_status) && route.text !== 'Settings') ? 'disable' : ''}`}
																// style={(route.has_nested_routes && openedMenuIndex.includes(index)) ? { } : {}}
																id={index}
																iconClass={route.iconClass}
																title={route.text}
																has_nested_routes={(route?.has_nested_routes && Object.entries(route?.nested_routes)?.filter((_route) => { return ((_route[1]?.required_roles?.filter((_role) => { return (_role === auth.data.role.name); }).length > 0 && (_route.length > 0 ? _route[1]?.is_menu_item === true : false))); }).length > 0)}
																minusIcon={route.minusIcon}
																plusIcon={route.plusIcon}
																onClick={(id) => {
																	openMenuItemLink(index, route.has_nested_routes, route);
																}}
																isSelected={(index === selected.menuItemIndex && !route.has_nested_routes)}
																opened={openedMenuIndex.filter((_i) => { return (_i === index); }).length > 0}
																hasUnreadNotifications={hasUnreadNotifications}
															/>
															{
																openedMenuIndex.filter((_i) => { return (_i === index); }).length > 0
																	?
																	<>
																		{
																			((route?.has_nested_routes && Object.entries(route?.nested_routes)?.filter((_route) => { return ((_route[1]?.required_roles?.filter((_role) => { return (_role === auth.data.role.name); }).length > 0 && (_route.length > 0 ? _route[1]?.is_menu_item === true : false))); }).length > 0)) &&
																			<div className='d-flex flex-column '>
																				{
																					Object.values(route.nested_routes).map((nested_route, i, arr) => {
																						if (nested_route.is_menu_item && nested_route?.required_roles?.some(item => auth.data.role.name === item)) {
																							return (
																								<>
																									{fullBlock.includes(auth.data.tenant_status) ?
																										<MenuItem
																											className={`sub-menu-item sub-menu-item-hover-effect ${(index === selected?.menuItemIndex && i === selected?.menuSubItemIndex) && ' sub-item-selected '}
																												${(fullBlock.includes(auth.data.tenant_status) && ((auth.data.role.name === 'Owner' && nested_route.text !== 'Billing') || ((auth.data.role.name === 'Admin' || auth.data.role.name === 'Member') && nested_route.text !== 'Personal'))) ? 'disable' : ''}
																											`}
																											id={i}
																											iconClass={nested_route.iconClass}
																											title={nested_route.text}
																											// style={{ borderBottom: i + 1 === arr.length ? '1px solid #E1E1E1' : 'none', marginTop: i === 0 ? '0px' : '5px' }}
																											onClick={(id) => handleSelectedSubMenu(index, id)}
																											isSelected={i === selected?.menuSubItemIndex}
																										/>
																										:
																										<Link to={`${route.pathname}${nested_route.pathname}`} style={{ textDecoration: 'none' }} key={`${nested_route.pathname}_${i}`}>
																											<MenuItem
																												className={`sub-menu-item sub-menu-item-hover-effect ${(index === selected?.menuItemIndex && i === selected?.menuSubItemIndex) && ' sub-item-selected '}
																													${(fullBlock.includes(auth.data.tenant_status) && ((auth.data.role.name === 'Owner' && nested_route.text !== 'Billing') || ((auth.data.role.name === 'Admin' || auth.data.role.name === 'Member') && nested_route.text !== 'Personal'))) ? 'disable' : ''}
																												`}
																												id={i}
																												iconClass={nested_route.iconClass}
																												title={nested_route.text}
																												// style={{ borderBottom: i + 1 === arr.length ? '1px solid #E1E1E1' : 'none', marginTop: i === 0 ? '0px' : '5px' }}
																												onClick={(id) => handleSelectedSubMenu(index, id)}
																												isSelected={i === selected?.menuSubItemIndex}
																											/>
																										</Link>
																									}
																								</>
																							);
																						}
																					})
																				}
																			</div>
																		}
																	</> : null
															}
														</div>

													);
												}
											})
										}
										{/* <div className='notification-container'>
											<div className='notification' onClick={() => { history.push(`${PathName.NOTIFICATIONS}`); }}>
												<BellIcon className='notification-bell-icon' style={{ marginRight: hasUnreadNotifications ? '25px' : '12px' }} />
												{
													hasUnreadNotifications &&
													<div style={{ width: '15px', height: '15px', border: '2px solid #fff', borderRadius: '50%', backgroundColor: 'red', marginLeft: '-35px', marginRight: '8px' }}></div>
												}
												<span className='body-3 semibold' style={{ color: '#fff' }}>Notifications</span>
											</div>
										</div> */}
									</div>
									<div className='side-menu-footer'>
										{/* <div className='support-container'>
											<div className='support d-flex flex-row' onClick={handleToggleSupport}>
												<SupportIcon className='support-icon' />
												<span className='body-3'>Support</span>
												{
													view &&
													<SupportCard />
												}
											</div>
										</div> */}
										<div className='side-menu-avatar d-flex flex-row ' style={{ position: 'relative' }} tabIndex={-1} {...focusWithinProps}>
											<div className='side-menu-avatar-logo d-flex flex-row' onClick={handleToggleProfile}>
												<AvtarBadge
													// className='avatar'
													status="online"
													size={'custom'}
													width={'40px'}
													height={'40px'}
													alt={`${userProfileData?.first_name} ${userProfileData?.last_name}`}
													src={userImage} />
												{userProfileData == undefined ?
													<ProcessingIndicator varrient='grey' className='medium-indicator' />
													:
													<div className='side-menu-avatar-logo-text d-flex flex-column'>
														<span className='body-3'>{truncateName(Name)}</span>
														{/* <span className='body-4 w-100' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{userProfileData?.email}</span> */}
													</div>
												}
												<ArrowIcon className="side-menu-avatar-arrow" style={{ width: '12px', height: '12px',marginRight:'10px' }} />
												{
													show &&
													<ProfileCard
														name={`${userProfileData?.first_name} ${userProfileData?.last_name}`}
														image={userImage}
														email={userProfileData?.email}
													/>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					:
					isOnTabletScreen
						?
						<div style={{ width: '80px', position: 'relative' }}>
							<div className={`side-menu-wrapper side-menu-collapsed`}>
								<div className='side-menu'>

									<NavLink to={PathName.MAIN} className='side-menu-logo' style={{ cursor: 'pointer', textDecoration: 'none' }}>
										<Logo />
										<span style={{ color: ' #121216', fontWeight: '500', fontSize: '22px' }}>testfuse</span>
										{/* <span onClick={() => setCommonValues({ ...commonValues, isSideBarExpanded: !commonValues.isSideBarExpanded })} >
									<DiscIcon className='disc-icon' />
								</span> */}
									</NavLink>

									<div className='d-flex flex-column justify-content-between hide-scrollbar' style={{ height: 'calc(100% - 88px)' }}>
										<div className='d-flex flex-column hide-scrollbar' style={{ height: 'calc(100% - 69.5px)', overflow: 'scroll', gap: '24px' }}>

											{
												Object.values(DashboardRoutes).map((route, index) => {
													if (!route?.unvisible_for_roles?.some(item => auth.data.role.name === item) && route.is_menu_item && route.required_roles.some(item => auth.data.role.name === item)) {

														return (
															<div key={index} >
																<MenuItem
																// className={`pointer side-menu-item ${index === selected.menuItemIndex ? (route.has_nested_routes && openedMenuIndex.includes(index)) ? ' ' : ' side-menu-selected ' : ' '} ${(route.has_nested_routes && openedMenuIndex.includes(index)) ? ' ' : ' menu-item-hover-effect '}`}
																	className={`pointer side-menu-item ${(index === selected.menuItemIndex && (!route.has_nested_routes || route.pathname === PathName.CANDIDATES || !openedMenuIndex.includes(index))) && ' side-menu-selected '} ${(route.has_nested_routes && openedMenuIndex.includes(index)) ? ' ' : ' menu-item-hover-effect '} ${(fullBlock.includes(auth.data.tenant_status) && route.text !== 'Settings') ? 'disable' : ''}`}
																	// style={(route.has_nested_routes && openedMenuIndex.includes(index)) ? { } : {}}
																	id={index}
																	iconClass={route.iconClass}
																	title={route.text}
																	has_nested_routes={(route?.has_nested_routes && Object.entries(route?.nested_routes)?.filter((_route) => { return ((_route[1]?.required_roles?.filter((_role) => { return (_role === auth.data.role.name); }).length > 0 && (_route.length > 0 ? _route[1]?.is_menu_item === true : false))); }).length > 0)}
																	minusIcon={route.minusIcon}
																	plusIcon={route.plusIcon}
																	onClick={(id) => {
																		openMenuItemLink(index, route.has_nested_routes, route);
																	}}
																	isSelected={(index === selected.menuItemIndex && !route.has_nested_routes)}
																	opened={openedMenuIndex.filter((_i) => { return (_i === index); }).length > 0}
																	hasUnreadNotifications={hasUnreadNotifications}
																/>
																{
																	openedMenuIndex.filter((_i) => { return (_i === index); }).length > 0
																		?
																		<>
																			{
																				((route?.has_nested_routes && Object.entries(route?.nested_routes)?.filter((_route) => { return ((_route[1]?.required_roles?.filter((_role) => { return (_role === auth.data.role.name); }).length > 0 && (_route.length > 0 ? _route[1]?.is_menu_item === true : false))); }).length > 0)) &&
																			<div className='d-flex flex-column '>
																				{
																					Object.values(route.nested_routes).map((nested_route, i, arr) => {
																						if (nested_route.is_menu_item && nested_route?.required_roles?.some(item => auth.data.role.name === item)) {
																							return (
																								<>
																									{fullBlock.includes(auth.data.tenant_status) ?
																										<MenuItem
																											className={`sub-menu-item sub-menu-item-hover-effect ${(index === selected?.menuItemIndex && i === selected?.menuSubItemIndex) && ' sub-item-selected '}
																												${(fullBlock.includes(auth.data.tenant_status) && ((auth.data.role.name === 'Owner' && nested_route.text !== 'Billing') || ((auth.data.role.name === 'Admin' || auth.data.role.name === 'Member') && nested_route.text !== 'Personal'))) ? 'disable' : ''}
																											`}
																											id={i}
																											iconClass={nested_route.iconClass}
																											title={nested_route.text}
																											// style={{ borderBottom: i + 1 === arr.length ? '1px solid #E1E1E1' : 'none', marginTop: i === 0 ? '0px' : '5px' }}
																											onClick={(id) => handleSelectedSubMenu(index, id)}
																											isSelected={i === selected?.menuSubItemIndex}
																										/>
																										:
																										<Link to={`${route.pathname}${nested_route.pathname}`} style={{ textDecoration: 'none' }} key={`${nested_route.pathname}_${i}`}>
																											<MenuItem
																												className={`sub-menu-item sub-menu-item-hover-effect ${(index === selected?.menuItemIndex && i === selected?.menuSubItemIndex) && ' sub-item-selected '}
																													${(fullBlock.includes(auth.data.tenant_status) && ((auth.data.role.name === 'Owner' && nested_route.text !== 'Billing') || ((auth.data.role.name === 'Admin' || auth.data.role.name === 'Member') && nested_route.text !== 'Personal'))) ? 'disable' : ''}
																												`}
																												id={i}
																												iconClass={nested_route.iconClass}
																												title={nested_route.text}
																												// style={{ borderBottom: i + 1 === arr.length ? '1px solid #E1E1E1' : 'none', marginTop: i === 0 ? '0px' : '5px' }}
																												onClick={(id) => handleSelectedSubMenu(index, id)}
																												isSelected={i === selected?.menuSubItemIndex}
																											/>
																										</Link>
																									}
																								</>
																							);
																						}
																					})
																				}
																			</div>
																			}
																		</> : null
																}
															</div>

														);
													}
												})
											}
											{/* <div className='notification-container'>
											<div className='notification' onClick={() => { history.push(`${PathName.NOTIFICATIONS}`); }}>
												<BellIcon className='notification-bell-icon' style={{ marginRight: hasUnreadNotifications ? '25px' : '12px' }} />
												{
													hasUnreadNotifications &&
													<div style={{ width: '15px', height: '15px', border: '2px solid #fff', borderRadius: '50%', backgroundColor: 'red', marginLeft: '-35px', marginRight: '8px' }}></div>
												}
												<span className='body-3 semibold' style={{ color: '#fff' }}>Notifications</span>
											</div>
										</div> */}
										</div>
										<div className='side-menu-footer'>
											{/* <div className='support-container'>
											<div className='support d-flex flex-row' onClick={handleToggleSupport}>
												<SupportIcon className='support-icon' />
												<span className='body-3'>Support</span>
												{
													view &&
													<SupportCard />
												}
											</div>
										</div> */}
											<div className='side-menu-avatar d-flex flex-row ' style={{ position: 'relative' }} tabIndex={-1} {...focusWithinProps}>
												<div className='side-menu-avatar-logo d-flex flex-row' onClick={handleToggleProfile}>
													<AvtarBadge
													// className='avatar'
														status="online"
														size={'custom'}
														width={'40px'}
														height={'40px'}
														alt={`${userProfileData?.first_name} ${userProfileData?.last_name}`}
														src={userImage} />
													{userProfileData == undefined ?
														<ProcessingIndicator varrient='grey' className='medium-indicator' />
														:
														<div className='side-menu-avatar-logo-text d-flex flex-column' style={{ marginLeft: '12px' }}>
															<span className='body-3'>{truncateName(Name)}</span>
															{/* <span className='body-4 w-100' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{userProfileData?.email}</span> */}
														</div>
													}
													<ArrowIcon className="side-menu-avatar-arrow" style={{ width: '12px', height: '12px' }} />
													{
														show &&
													<ProfileCard
														name={`${userProfileData?.first_name} ${userProfileData?.last_name}`}
														image={userImage}
														email={userProfileData?.email}
													/>
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						:
						<div className='side-menu-wrapper'>
							<div className='side-menu' >
								<div className='menu-collapse'>
									<MenuIcon className="menu-icon" onClick={() => { setisMobileMenuOpen(true); }} />
								</div>
								<NavLink to={PathName.MAIN} className='side-menu-logo' style={{ cursor: 'pointer', textDecoration: 'none', marginTop: '-8px' }}>
									<Logo style={{ width: '32px', height: '32px' }} />
									<span className='headline-4 dark-100' style={{ color: '#fff', marginLeft: '4px' }}>testfuse</span>
								</NavLink>
							</div>
							<div className={`menu-open ${!isMobileMenuOpen && 'menu-close'}`}>
								<div className='menu'>
									<div className='menu-header'>
										<CrossIcon style={{ position: 'absolute', left: '20px', top: '27px' }} onClick={() => { setisMobileMenuOpen(false); }} />
										<NavLink to={PathName.MAIN} onClick={() => { setisMobileMenuOpen(false); }} className='side-menu-logo d-flex align-items-center' style={{ cursor: 'pointer', textDecoration: 'none', marginTop: '24px' }}>
											<Logo />
											<span className='headline-4 dark-100' >testfuse</span>
										</NavLink>
									</div>
									<div className='menu-items'>
										<div className='d-flex flex-column' style={{ gap: '8px' }}>
											{
												Object.values(DashboardRoutes).map((route, index) => {
													if (!route?.unvisible_for_roles?.some(item => auth.data.role.name === item) && route.is_menu_item && route.required_roles.some(item => auth.data.role.name === item)) {
														return (
															<div key={index} >
																<MenuItem
																	// className={`pointer side-menu-item ${index === selected.menuItemIndex ? (route.has_nested_routes && openedMenuIndex.includes(index)) ? ' ' : ' side-menu-selected ' : ' '} ${(route.has_nested_routes && openedMenuIndex.includes(index)) ? ' ' : ' menu-item-hover-effect '}`}
																	className={`pointer side-menu-item ${(index === selected.menuItemIndex && (!route.has_nested_routes || route.pathname === PathName.CANDIDATES || !openedMenuIndex.includes(index))) && ' side-menu-selected '} ${(route.has_nested_routes && openedMenuIndex.includes(index)) ? ' ' : ' menu-item-hover-effect '} ${(fullBlock.includes(auth.data.tenant_status) && route.text !== 'Settings') ? 'disable' : ''}`}
																	// style={(route.has_nested_routes && openedMenuIndex.includes(index)) ? { } : {}}
																	id={index}
																	iconClass={route.iconClass}
																	title={route.text}
																	has_nested_routes={(route?.has_nested_routes && Object.entries(route?.nested_routes)?.filter((_route) => { return ((_route[1]?.required_roles?.filter((_role) => { return (_role === auth.data.role.name); }).length > 0 && (_route.length > 0 ? _route[1]?.is_menu_item === true : false))); }).length > 0)}
																	minusIcon={route.minusIcon}
																	plusIcon={route.plusIcon}
																	onClick={(id) => {
																		openMenuItemLink(index, route.has_nested_routes, route);
																	}}
																	isSelected={(index === selected.menuItemIndex && !route.has_nested_routes)}
																	opened={openedMenuIndex.filter((_i) => { return (_i === index); }).length > 0}
																	hasUnreadNotifications={hasUnreadNotifications}
																/>
																{
																	openedMenuIndex.filter((_i) => { return (_i === index); }).length > 0
																		?
																		<>
																			{
																				((route?.has_nested_routes && Object.entries(route?.nested_routes)?.filter((_route) => { return ((_route[1]?.required_roles?.filter((_role) => { return (_role === auth.data.role.name); }).length > 0 && (_route.length > 0 ? _route[1]?.is_menu_item === true : false))); }).length > 0)) &&
																				<div className='d-flex flex-column '>
																					{
																						Object.values(route.nested_routes).map((nested_route, i, arr) => {
																							if (nested_route.is_menu_item && nested_route?.required_roles?.some(item => auth.data.role.name === item)) {
																								return (
																									<>
																										{fullBlock.includes(auth.data.tenant_status) ?
																											<MenuItem
																												className={`sub-menu-item sub-menu-item-hover-effect ${(index === selected?.menuItemIndex && i === selected?.menuSubItemIndex) && ' sub-item-selected '}
																												${(fullBlock.includes(auth.data.tenant_status) && ((auth.data.role.name === 'Owner' && nested_route.text !== 'Billing') || ((auth.data.role.name === 'Admin' || auth.data.role.name === 'Member') && nested_route.text !== 'Personal'))) ? 'disable' : ''}
																											`}
																												id={i}
																												iconClass={nested_route.iconClass}
																												title={nested_route.text}
																												// style={{ borderBottom: i + 1 === arr.length ? '1px solid #E1E1E1' : 'none', marginTop: i === 0 ? '0px' : '5px' }}
																												onClick={(id) => handleSelectedSubMenu(index, id)}
																												isSelected={i === selected?.menuSubItemIndex}
																											/>
																											:
																											<Link to={`${route.pathname}${nested_route.pathname}`} style={{ textDecoration: 'none' }} key={`${nested_route.pathname}_${i}`}>
																												<MenuItem
																													className={`sub-menu-item sub-menu-item-hover-effect ${(index === selected?.menuItemIndex && i === selected?.menuSubItemIndex) && ' sub-item-selected '}
																													${(fullBlock.includes(auth.data.tenant_status) && ((auth.data.role.name === 'Owner' && nested_route.text !== 'Billing') || ((auth.data.role.name === 'Admin' || auth.data.role.name === 'Member') && nested_route.text !== 'Personal'))) ? 'disable' : ''}
																												`}
																													id={i}
																													iconClass={nested_route.iconClass}
																													title={nested_route.text}
																													// style={{ borderBottom: i + 1 === arr.length ? '1px solid #E1E1E1' : 'none', marginTop: i === 0 ? '0px' : '5px' }}
																													onClick={(id) => handleSelectedSubMenu(index, id)}
																													isSelected={i === selected?.menuSubItemIndex}
																												/>
																											</Link>
																										}
																									</>
																								);
																							}
																						})
																					}
																				</div>
																			}
																		</> : null
																}
															</div>
														);
													}
												})
											}
										</div>
										<div className='side-menu-footer'>
											<div className='side-menu-avatar d-flex flex-row w-100' style={{ position: 'relative' }} tabIndex={-1} {...focusWithinProps}>
												<div className='side-menu-avatar-logo d-flex flex-row w-100' onClick={handleToggleProfile}>
													<AvtarBadge
														// className='avatar'
														status="online"
														size={'custom'}
														width={'40px'}
														height={'40px'}
														alt={`${userProfileData?.first_name} ${userProfileData?.last_name}`}
														src={userImage} />
													<div className='w-100 d-flex justify-content-between '>
														{userProfileData == undefined ?
															<ProcessingIndicator varrient='grey' className='medium-indicator' />
															:
															<div className='side-menu-avatar-logo-text d-flex flex-column' style={{ marginLeft: '12px' }}>
																<span className='body-3'>{truncateName(Name)}</span>
																{/* <span className='body-4 w-100' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{userProfileData?.email}</span> */}
															</div>
														}
														<ArrowIcon className="side-menu-avatar-arrow" style={{ width: '12px', height: '12px' }} />
														{
															show &&
															<ProfileCard
																name={`${userProfileData?.first_name} ${userProfileData?.last_name}`}
																image={userImage}
																email={userProfileData?.email}
															/>
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
			}
		</>
	);

}


SideMenu.propTypes = {

};
