import React, { useState, useEffect, useMemo } from 'react';
import ProgressBar from '../../../../components/common/ProgressBar';
import Table from '../../../../components/common/Table';
import Input from '../../../../components/sub-component/Input';
import AvtarBadge, {
	GetAvtarImage,
} from '../../../../components/dashboard/AvtarBadge';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { PathName } from '../../../../utils/routes';
import Button from '../../../../components/sub-component/Button';
import {
	calculateTimeDifferenceInDays,
	timeDifference,
} from '../../../../utils/utilities';
import {
	AssessmentSpecStatus,
	RequestStatus,
	inviteBlock,
} from '../../../../utils/Constants';
// import Button from '../../../components/sub-component/Button';
// import Select from '../../../components/sub-component/Select';

import DeskImage from '../../../../assets/images/dashboard/Illustration.svg';
import AssessmentIcon from '../../../../assets/icons/Views/dashboard/assessments/Avatar.png';

import { ReactComponent as PlusIcon } from './../../../../assets/icons/Views/dashboard/assessments/plus.svg';
import { ReactComponent as PlusBtnIcon } from '../../../../assets/icons/components/dashboard/plusBtn.svg';
import { ReactComponent as AssessmentsStatsIcon } from '../../../../assets/icons/Views/dashboard/assessments/assessments-stats.svg';
import { ReactComponent as InvitedStatsIcon } from '../../../../assets/icons/Views/dashboard/assessments/invite-stats.svg';
import { ReactComponent as AttemptsStatsIcon } from '../../../../assets/icons/Views/dashboard/assessments/attempt-stats.svg';
import { ReactComponent as ActiveStatsIcon } from '../../../../assets/icons/components/active.svg';
import { ReactComponent as DraftStatsIcon } from '../../../../assets/icons/Views/dashboard/assessments/draft-stats.svg';
import { ReactComponent as ArchiveStatsIcon } from '../../../../assets/icons/Views/dashboard/assessments/archive-stats.svg';
import { ReactComponent as Search } from '../../../../assets/icons/Views/candidate/assessments/search.svg';
import { ReactComponent as OpenAIIcon } from '../../../../assets/icons/Views/dashboard/assessments/chat gpt.svg';
import { ReactComponent as BackArrow } from '../../../../assets/icons/Arrows/CaretLeft.svg';
import { ReactComponent as ForwardArrow } from '../../../../assets/icons/Arrows/CaretRight.svg';

import { ReactComponent as LinkIcon } from '../../../../assets/icons/Views/dashboard/test-detail/link.svg';
import { ReactComponent as ArchiveIcon } from '../../../../assets/icons/Views/dashboard/test-detail/archive.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/Views/dashboard/test-detail/btrash.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/Views/dashboard/test-detail/copy.svg';
import { useSelector, useDispatch } from 'react-redux';
import CustomModal from '../../../../components/common/Modal';
import {
	archiveAssessSpec,
	cloneAssessSpec,
	deleteAssessSpec,
	resetCurrAssessSpec,
} from '../../../../redux/thunks/AssessSpec';
import { getResource } from '../../../../redux/thunks/Resource';
import { useMediaQuery } from '@mui/material';
import AlertBox from '../../../../components/common/AlertBox';
import CustomTooltip from '../../../../components/common/CustomTooltip';

/**
 *
 * @param {*} props
 * @returns
 */
export default function Assessments(props) {
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const isOnTabScreen = useMediaQuery('(max-width: 1200px)');
	const { data, handleCreateAssessSpec, setActionAssessmentModal, actionAssessmentModal, assessSpecProcessing, assessSpecLoading, totalAssessments, activeAssessments, draftAssessments, archiveAssessments, totalInvited, totalAttempted, routeText, variant, setSelectedsortOption, selectedsortOption, setSearchQuery, searchQuery, setCurrentPage, currentPage, totalItems, itemsPerPage, totalPages, nextSlide, prevSlide } = props;
	const dispatch = useDispatch();
	const history = useHistory();
	const auth = useSelector((state) => state.authReducer);
	const subscription = useSelector((state) => state.subscriptionReducer);
	const expireAt =
		subscription?.user_subscription.length > 0
			? subscription?.user_subscription[0]?.expires_at
			: null;
	const daysLeft = calculateTimeDifferenceInDays(expireAt);
	const openLeftDaysDisclaimer = sessionStorage.getItem(
		'openLeftDaysDisclaimer'
	);

	const [customSearch, setCustomSearch] = useState();
	const [loading, setLoading] = useState(true);
	const [testData, setTestData] = useState([]);
	const [alert, setAlert] = useState(null);


	const assessmentTableHeader = [
		{
			id: 'assessmentTitle',
			name: 'Test Title',
			type: 'component',
			disableSorting: true,
			screens: ['web', 'tab', 'mob'],
		},
		{
			id: 'invitedCandidates',
			name: 'Invited Candidates',
			type: 'component',
			disableSorting: true,
			screens: ['web', 'tab'],
		},
		{
			id: 'created_at',
			name: 'Created at',
			type: 'string',
			disableSorting: true,
			backendSorting: true,
			screens: ['web'],
			timeDifference: true
		},
		{
			id: 'updated_at',
			name: 'Last Activity',
			type: 'string',
			disableSorting: true,
			backendSorting: true,
			screens: ['web'],
			timeDifference: true
		},
		{
			id: 'progress',
			name: 'Completion Rate',
			type: 'component',
			disableSorting: true,
			screens: ['web', 'tab'],
		},
		{
			id: 'action',
			name: 'Actions',
			type: 'component',
			disableSorting: true,
			screens: ['web', 'tab', 'mob'],
		},
	];

	const assessmentTableHeaderViewOnly = [
		{
			id: 'assessmentTitle',
			name: 'Test Title',
			type: 'component',
			disableSorting: true,
			screens: ['web', 'tab', 'mob'],
		},
		{
			id: 'invitedCandidates',
			name: 'Invited Candidates',
			type: 'component',
			disableSorting: true,
			screens: ['web', 'tab'],
		},
		{
			id: 'created_at',
			name: 'Created at',
			type: 'string',
			disableSorting: false,
			screens: ['web'],
			timeDifference: true
		},
		{
			id: 'updated_at',
			name: 'Last Activity',
			type: 'string',
			disableSorting: false,
			screens: ['web'],
			timeDifference: true
		},
		{
			id: 'progress',
			name: 'Completion Rate',
			type: 'component',
			disableSorting: false,
			screens: ['web', 'tab'],
		},
	];
	const customSorting = {
		created_at: (a, b) => {
			const dateA = new Date(a);
			const dateB = new Date(b);
			return dateA - dateB;
		},
		updated_at: (a, b) => {
			const dateA = new Date(a);
			const dateB = new Date(b);
			return dateA - dateB;
		},
	};


	const searchColumns = ['title', 'createdAt', 'updatedAt'];

	const cloneAssessment = async (id, name) => {
		// history.push(`${PathName.ASSESSMENTS}/criteria/${params.id}`);
		const res = await dispatch(cloneAssessSpec({ id: id }));
		if (res?.meta?.requestStatus === RequestStatus.REJECTED) {
			setAlert(
				<AlertBox
					style={{ zIndex: '99999' }}
					color='danger'
					description={res?.payload}
					title='Error'
					isOpen={true}
					onCloseAlert={() => {
						setAlert(null);
					}}
				/>
			);
		} else {
			setAlert(
				<AlertBox
					style={{ zIndex: '99999' }}
					color='success'
					description={`${name} cloned successfully!`}
					title='Success'
					isOpen={true}
					onCloseAlert={() => {
						setAlert(null);
					}}
				/>
			);
			history.push(`${PathName.ASSESSMENTS}/create/${res?.payload?.data?.id}`);
		}
	};

	const transformedData = useMemo(() => {
		if (!data) {
			return [];
		}

		return data.map((item, index) => {
			return {
				assessmentTitle: (
					<div className='d-flex align-items-center' style={{ gap: '6px' }}>

						<GetAvtarImage
							fontSize='0.75em'
							size='custom'
							height='26px'
							width='26px'
							turnOnBGColors
							src={{ image_id: item.avatar_id, name: item.title }}
						/>
						<span className='truncate-title' style={{ fontSize: '14px', fontWeight: '400' }}>{item.title}</span>
					</div>
				),
				invitedCandidates:
					item.candidates.length > 0 ? (
						<AvtarBadge
							key={1}
							showTooltip={true}
							varrient='multiple-badges'
							turnOnBGColors
							users={item?.candidates?.map((_item, _) => {
								return {
									..._item,
									name: `${_item.first_name}${_item.last_name}`,
									nameInitals: `${_item.first_name?.charAt(0) + _item.last_name?.charAt(0)
									}`,
								};
							})}
						/>
					) : (
						'0'
					),
				createdAt: item.created_at,
				updatedAt: item.updated_at,
				progress: (
					<CustomTooltip
						position='top-end'
						width={'200px'}
						text={`${item.completed} out of ${item.invited} candidates have completed the assessment.`}
					>
						<ProgressBar
							value={parseFloat(
								((item.completed / (item.invited || 1)) * 100).toFixed(1)
							)}
							key='1'
						/>
					</CustomTooltip>
				),
				action: (
					<div
						className='d-flex justify-content-between  align-items-center'
						key={`icon_${index}`}
						style={{ width: 'fit-content', gap: '6px' }}
					>
						{/* <div style={{border: '1px solid', padding: '1px 2px', borderRadius: '50%'}}> */}

						{/* <div className='icon-wrapper'
								onClick={(e) => {
									e.stopPropagation();
									// alert('Clicked');
								}}
							>
								<span className='user-plus-icon icon-black-500' style={{ width: '20px', height: '20px' }} />
							</div> */}
						{item.status === 'ACTIVE' && (
							<>
								{auth.data.role.name !== 'Member' && (
									<>
										{inviteBlock.includes(auth?.data?.tenant_status) ? (
											<CustomTooltip
												customPosition={{ bottom: '12px', left: '8px' }}
												text={
													'You cannot share an assessment unless you fix your billing issues. Choose a plan from billing settings or pay your pending invoices.'
												}
											>
												<div
													className='d-flex align-items-center'
													style={{ height: '34px', opacity: '0.3' }}
													onClick={(e) => {
														e.stopPropagation();
													}}
												>
													<span
														className='link-icon icon-black-500'
														style={{ width: '24px', height: '24px' }}
													/>
												</div>
											</CustomTooltip>
										) : (
											<CustomTooltip
												position='top-end'
												text={'Copy Invite Link'}
											>
												<div
													className='icon-wrapper active-bg-success'
													style={{ height: '34px' }}
													onClick={(e) => {
														e.stopPropagation();
														navigator.clipboard.writeText(
															`${window.location.origin}/?invite_id=${item?.invite_id}`
														);
														setAlert(
															<AlertBox
																style={{ zIndex: '99999' }}
																color='success'
																description={`${item?.title} assessment invite link copied successfully!`}
																title='Success'
																isOpen={true}
																onCloseAlert={() => {
																	setAlert(null);
																}}
															/>
														);
													}}
												>
													<LinkIcon stroke={'#808191'} width='20px' height='20px' />
												</div>
											</CustomTooltip>
										)}
									</>
								)}
								<CustomTooltip position='top-end' text='Archive Assessment'>
									<div
										className='icon-wrapper'
										style={{ height: '34px' }}
										onClick={(e) => {
											e.stopPropagation();
											// alert('Clicked');
											setActionAssessmentModal({
												isOpen: true,
												assessSpec: item,
												action: 'archive',
											});
										}}
									>
										<ArchiveIcon stroke={'#808191'} width='20px' height='20px' />
									</div>
								</CustomTooltip>
							</>
						)}
						{item.status === 'DRAFT' && (
							<CustomTooltip position='top-end' text='Delete Assessment'>
								<div
									className='icon-wrapper'
									style={{ height: '34px' }}
									onClick={(e) => {
										e.stopPropagation();
										setActionAssessmentModal({
											isOpen: true,
											assessSpec: item,
											action: 'delete',
										});
									}}
								>
									<DeleteIcon stroke={'#808191'} width='20px' height='20px' />
								</div>
							</CustomTooltip>
						)}
						<CustomTooltip position='top-end' text='Clone Assessment'>
							<div
								className='icon-wrapper'
								onClick={(e) => {
									e.stopPropagation();
									setActionAssessmentModal({
										isOpen: true,
										assessSpec: item,
										action: 'clone',
									});
								}}
							>
								<CopyIcon stroke={'#808191'} width='20px' height='20px' />
							</div>
						</CustomTooltip>
					</div>
				),
				// item.status,
				// item.id,
				...item,
			};
		});
	}, [data, variant]);


	useEffect(() => {
		if (data === null) {
			setTestData([]);
		} else {
			setTestData(transformedData);
		}
		setLoading(false);
	}, [data, transformedData]);

	const handleRowSelect = (row) => {
		if (
			row.status === AssessmentSpecStatus.ACTIVE ||
			row.status === AssessmentSpecStatus.ARCHIVED
		) {
			history.push(`${PathName.ASSESSMENTS}/${row.id}`);
		} else {
			history.push(`${PathName.ASSESSMENTS}/create/${row.id}`);
		}
	};

	useEffect(() => {
		dispatch(resetCurrAssessSpec());
	}, [dispatch]);

	const defaultAssessmentCard = () => {
		return (
			<>
				<div className='default-assessment-card-container'>
					<div className='default-assessment-text' style={{ marginTop: '24px' }}>
						<span className='body-1 dark-100'>{
							`You don't have any ${variant === 'archived' ? 'archived' : variant === 'draft' ? 'draft' : 'active'} assessments. Please click the Create New Assessment button above to create a new assessment`}</span>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			{alert}

			{
				<>
					<CustomModal
						open={actionAssessmentModal.isOpen}
						varrient='alert-warning-modal'
						processing={assessSpecProcessing}
						title={`${actionAssessmentModal.action === 'archive'
							? 'Archive'
							: actionAssessmentModal.action === 'delete'
								? 'Delete'
								: 'Clone'
						} Assessment ?`}
						description={`Are you sure you want to ${actionAssessmentModal.action === 'archive'
							? 'archive'
							: actionAssessmentModal.action === 'delete'
								? 'delete'
								: 'clone'
						} this assessment?`}
						dangerAlert={actionAssessmentModal.action === 'delete' && true}
						warningAlert={actionAssessmentModal.action !== 'delete' && true}
						handleCloseModal={() => {
							setActionAssessmentModal({
								isOpen: false,
								assessSpec: null,
								action: '',
							});
						}}
						confirmBtnTitle={
							actionAssessmentModal.action === 'archive'
								? 'Archive'
								: actionAssessmentModal.action === 'delete'
									? 'Delete'
									: 'Clone'
						}
						onConfirm={async () => {
							if (actionAssessmentModal.action === 'archive') {
								await dispatch(
									archiveAssessSpec({
										// data: actionAssessmentModal?.assessSpec,
										data: data.filter((assessSpec, _) => { return assessSpec.id !== actionAssessmentModal?.assessSpec?.id; }),
										id: actionAssessmentModal?.assessSpec?.id,
									})
								);
							} else if (actionAssessmentModal.action === 'clone') {
								cloneAssessment(
									actionAssessmentModal?.assessSpec?.id,
									actionAssessmentModal?.assessSpec?.title
								);
							} else if (actionAssessmentModal.action === 'delete') {
								await dispatch(
									deleteAssessSpec({
										// data: actionAssessmentModal?.assessSpec,
										data: data.filter((assessSpec, _) => { return assessSpec.id !== actionAssessmentModal?.assessSpec?.id; }),
										id: actionAssessmentModal?.assessSpec?.id,
									})
								);
							}
							setActionAssessmentModal({
								isOpen: false,
								assessSpec: null,
								action: '',
							});
						}}
					/>
				</>
			}

			{
				<div className='w-100 dashboard-page-mobile'>
					{isOnMobileScreen ? (
						<div className='dashboard-page' style={{ width: '100%' }}>
							<div className=' d-flex align-items-center justify-content-between' style={{ width: '99%', margin: 'auto' }}>
								<span className='subtitle-1 dark-100'>{`${routeText} Assessments`}</span>
								<div className='d-flex' style={{ height: '52px', marginTop: '16px', gap: '16px' }}>
									<div
										onClick={handleCreateAssessSpec}
										className='d-flex align-items-center justify-content-center'
										style={{
											height: '44px',
											width: '44px',
											background: '#121216',
											borderRadius: '50px',
										}}
									>
										<PlusIcon color='white' stroke='white' />
									</div>
								</div>
							</div>
							<div
								className={`${daysLeft < 6 &&
									auth?.data?.tenant_status === 'trialing' &&
									!openLeftDaysDisclaimer &&
									'left-day-message-main-box'
								} page-content`}
							>
								<>
									<div className='assessments-stats-container mt-3' style={{ overflow: 'hidden' }}>
										<div className='assessments-stats'>
											<div className='assessments-stats-card'>
												<div className='stats-card-text'>
													<span className='body-2 dark-50 '>{isOnTabScreen ? 'Total' : 'Total Assessments'}</span>
													<span className=' headline-5 dark-100'>{totalAssessments}</span>
												</div>
												<div className='AssessmentsStatsIcon-container'>
													<AssessmentsStatsIcon className='AssessmentsStatsIcon' />
												</div>
											</div>
											<div className='assessments-stats-card'>
												<div className='stats-card-text'>
													<span className='body-2 dark-50'>{isOnTabScreen ? 'Invites' : 'Total Invites Sent'}											</span>
													<span className='headline-5 dark-100'>{totalInvited}</span>
												</div>
												<div className='AssessmentsStatsIcon-container'>
													<InvitedStatsIcon className='AssessmentsStatsIcon' />
												</div>
											</div>
											<div className='assessments-stats-card '>
												<div className='stats-card-text'>
													<span className='body-2 dark-50 '>{isOnTabScreen ? 'Attempts' : 'Total Attempts'}</span>
													<span className='headline-5 dark-100'>{totalAttempted}</span>
												</div>
												<div className='AssessmentsStatsIcon-container'>
													<AttemptsStatsIcon className='AssessmentsStatsIcon' />
												</div>
											</div>
											{routeText?.toUpperCase() === 'ARCHIVED' ?
												<div className='assessments-stats-card'>
													<div className='stats-card-text'>
														<span className='body-2 dark-50'>{isOnTabScreen ? 'Archived' : 'Archived Assessments'}</span>
														<span className='headline-5 dark-100 '>{archiveAssessments}</span>
													</div>
													<div className='AssessmentsStatsIcon-container'>
														<ArchiveStatsIcon className='AssessmentsStatsIcon' />
													</div>
												</div>
												:
												routeText?.toUpperCase() === 'DRAFT' ?
													<div className='assessments-stats-card'>
														<div className='stats-card-text'>
															<span className='body-2 dark-50'>{isOnTabScreen ? 'Draft' : 'Draft Assessments'}</span>
															<span className='headline-5 dark-100'>{draftAssessments}</span>
														</div>
														<div className='AssessmentsStatsIcon-container'>
															<DraftStatsIcon className='AssessmentsStatsIcon' />
														</div>
													</div>
													:
													<div className='assessments-stats-card'>
														<div className='stats-card-text'>
															<span className='body-2 dark-50'>{isOnTabScreen ? 'Active' : 'Active Assessments'}</span>
															<span className='headline-5 dark-100'>{activeAssessments}</span>
														</div>
														<div className='AssessmentsStatsIcon-container'>
															<ActiveStatsIcon className='AssessmentsStatsIcon' />
														</div>
													</div>
											}
										</div>
									</div>
									<div className='w-100 hide-scrollbar' style={{ height: 'calc(100vh - 265px)', borderRadius: '24px', background: 'white', overflowY: 'auto' }}>
										<div className='table-search-container'>
											<div className='table-search-input'>
												<Search height={'24px'} width={'24px'} />
												<Input
													inputStyle={{background: 'transparent', border: 'none'}}
													className={'w-100'}
													placeholder={'Search'}
													value={searchQuery}
													onChange={(e) => {
														setSearchQuery(e.target.value);
														setCurrentPage(1);
													}}
												/>	
											</div>
										</div>
										{(assessSpecLoading || loading) ?
											<div className='processing-indicator-wrapper medium-indicator w-100 h-100 d-flex align-items-center justify-content-center'>
												<div
													className='processing-indicator primary'
													style={{ width: '40px', height: '40px' }}
												/>
											</div>
											:
											<>
												{testData === null || !testData?.length ? (
													defaultAssessmentCard()
												) : (
													<Table
														headers={
															auth.data.role.name === 'Member'
																? assessmentTableHeaderViewOnly
																: assessmentTableHeader
														}
														data={testData}
														onSelectRow={handleRowSelect}
														disableSearchInput={true}
														// searchColumns={searchColumns}
														// customSorting={customSorting}
														// customSearch={customSearch}
														backendFilter={true}
														setSelectedsortOption={setSelectedsortOption}
														selectedsortOption={selectedsortOption}
														setCurrentPage={setCurrentPage}
														currentPage={currentPage}
														totalItems={totalItems}
														itemsPerPage={itemsPerPage}
														totalPages={totalPages}
														nextSlide={nextSlide}
														prevSlide={prevSlide}
														nullTableTitle={'No Assessments to show'}
														nullTableDescription={`Try adjusting your search query or filters`}
													/>
												)}
											</>
										}
									</div>
								</>
							</div>
						</div>
					) : (
						<div className='dashboard-page hide-scrollbar' style={{ overflow: 'hidden' }}>
							<div className=' d-flex align-items-center justify-content-between' style={{ padding: '0px 0px 24px', width: '99%', margin: 'auto' }}>
								<span className='headline-3 dark-100'>{`${routeText} Assessments`}</span>
								<div className='d-flex' style={{ height: '52px', marginTop: '16px', gap: '16px' }}>
									<Button
										preIcon={<PlusIcon color='white' />}
										style={{
											height: '44px',
											background: '#121216',
											padding: '12px 24px 12px 24px',
											// width:'260px',
											borderRadius: '50px',
											border: '0px'
										}}
										size='md'
										varrient='primary'
										title={isOnTabScreen ? 'Create New ' : ' Create New Assessment'}
										btn='ragular-btn'
										processing={assessSpecProcessing}
										onClick={handleCreateAssessSpec}
									/>
								</div>
							</div>
							<div
								className={`${daysLeft < 6 &&
									auth?.data?.tenant_status === 'trialing' &&
									!openLeftDaysDisclaimer &&
									'left-day-message-main-box'
								} page-content hide-scrollbar`}
								style={{ overflowY: 'auto' }}
							>
								<>
									<div className='assessments-stats-container mt-3 mb-3'>
										<div className='assessments-stats'>
											<div className='assessments-stats-card d-flex justify-content-between'>
												<div className='stats-card-text'>
													<span className='body-2 dark-50'>{isOnTabScreen ? 'Total' : 'Total Assessments'}</span>
													<span className='headline-5 dark-100'>{totalAssessments}</span>
												</div>
												<div className='AssessmentsStatsIcon-container'>
													<AssessmentsStatsIcon className='AssessmentsStatsIcon' />
												</div>
											</div>
											<div className='assessments-stats-card d-flex justify-content-between'>
												<div className='stats-card-text'>
													<span className='body-2 dark-50'>{isOnTabScreen ? 'Invites' : 'Total Invites Sent'}</span>
													<span className='headline-5 dark-100'>{totalInvited}</span>
												</div>
												<div className='AssessmentsStatsIcon-container'>
													<InvitedStatsIcon className='AssessmentsStatsIcon' />
												</div>
											</div>
											<div className='assessments-stats-card d-flex justify-content-between'>
												<div className='stats-card-text'>
													<span className='body-2 dark-50'>{isOnTabScreen ? 'Attempts' : 'Total Attempts'}</span>
													<span className='headline-5 dark-100'>{totalAttempted}</span>
												</div>
												<div className='AssessmentsStatsIcon-container'>
													<AttemptsStatsIcon className='AssessmentsStatsIcon' />
												</div>
											</div>
											{routeText?.toUpperCase() === 'ARCHIVED' ?
												<div className='assessments-stats-card d-flex justify-content-between'>
													<div className='stats-card-text'>
														<span className='body-2 dark-50'>{isOnTabScreen ? 'Archived' : 'Archived Assessments'}</span>
														<span className='headline-5 dark-100'>{archiveAssessments}</span>
													</div>
													<div className='AssessmentsStatsIcon-container'>
														<ArchiveStatsIcon className='AssessmentsStatsIcon' />
													</div>
												</div>
												:
												routeText?.toUpperCase() === 'DRAFT' ?
													<div className='assessments-stats-card d-flex justify-content-between'>
														<div className='stats-card-text'>
															<span className='body-2 dark-50'>{isOnTabScreen ? 'Draft' : 'Draft Assessments'}</span>
															<span className='headline-5 dark-100'>{draftAssessments}</span>
														</div>
														<div className='AssessmentsStatsIcon-container'>
															<DraftStatsIcon className='AssessmentsStatsIcon' />
														</div>
													</div>
													:
													<div className='assessments-stats-card d-flex justify-content-between'>
														<div className='stats-card-text'>
															<span className='body-2 dark-50'>{isOnTabScreen ? 'Active' : 'Active Assessments'}</span>
															<span className='headline-5 dark-100'>{activeAssessments}</span>
														</div>
														<div className='AssessmentsStatsIcon-container'>
															<ActiveStatsIcon className='AssessmentsStatsIcon' />
														</div>
													</div>
											}
										</div>
									</div>
									<div className='w-100 hide-scrollbar' style={{ height: 'calc(100vh - 260px)', borderRadius: '24px', background: 'white', overflowY: 'auto' }}>
										<div className='table-search-container'>
											<div className='table-search-input'>
												<Search height={'24px'} width={'24px'} />
												<Input
													inputStyle={{background: 'transparent', border: 'none'}}
													className={'w-100'}
													placeholder={'Search'}
													value={searchQuery}
													onChange={(e) => {
														setSearchQuery(e.target.value);
														setCurrentPage(1);
													}}
												/>	
											</div>
										</div>
										{(assessSpecLoading || loading) ?
											<div className='processing-indicator-wrapper medium-indicator w-100 h-100 d-flex align-items-center justify-content-center'>
												<div
													className='processing-indicator primary'
													style={{ width: '40px', height: '40px' }}
												/>
											</div>
											:
											<>
												{testData === null || !testData?.length ? (
													defaultAssessmentCard()
												) : (
													<Table
														headers={
															auth.data.role.name === 'Member'
																? assessmentTableHeaderViewOnly
																: assessmentTableHeader
														}
														data={testData}
														onSelectRow={handleRowSelect}
														disableSearchInput={true}
														// searchColumns={searchColumns}
														// customSorting={customSorting}
														// customSearch={customSearch}
														backendFilter={true}
														setSelectedsortOption={setSelectedsortOption}
														selectedsortOption={selectedsortOption}
														setCurrentPage={setCurrentPage}
														currentPage={currentPage}
														totalItems={totalItems}
														itemsPerPage={itemsPerPage}
														totalPages={totalPages}
														nextSlide={nextSlide}
														prevSlide={prevSlide}
														nullTableTitle={'No Assessments to show'}
														nullTableDescription={`Try adjusting your search query or filters`}
													/>
												)}
											</>
										}
									</div>
								</>
							</div>
						</div>
					)}
				</div>
			}
		</>
	);
}

Assessments.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
};
