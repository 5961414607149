import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ReactComponent as AssessmentUploadImageIcon } from '../../../assets/icons/Views/dashboard/assessments/default-upload.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/Views/dashboard/assessments/edit-3.svg';
import { ReactComponent as TimerIcon } from '../../../assets/icons/Views/dashboard/test-detail/HourglassMedium(1).svg';
import { ReactComponent as CreatedIcon } from '../../../assets/icons/Views/dashboard/assessments/check-circle.svg';
import LastActivity from '../../../assets/icons/Views/dashboard/test-detail/Vector.png';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/Views/dashboard/test-detail/calendar.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/Views/dashboard/test-detail/share.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/Views/dashboard/test-detail/trash2.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/Views/dashboard/test-detail/chevron-down.svg';
import { ReactComponent as Share2Icon } from '../../../assets/icons/Views/dashboard/test-detail/share-2.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/Views/dashboard/test-detail/user-plus.svg';
import { ReactComponent as RedTrashIcon } from '../../../assets/icons/Views/dashboard/test-detail/trash.svg';
import { ReactComponent as CopyIcon } from '../../../assets/icons/Views/dashboard/test-detail/copy.svg';
import { ReactComponent as FileIcon } from '../../../assets/icons/Views/dashboard/test-detail/file-text.svg';
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/Views/dashboard/test-detail/left-arrow.svg';
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/Views/dashboard/assessments/arrow-left.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/Views/dashboard/assessments/info.svg';
import { ReactComponent as TimeStatsIcon } from '../../../assets/icons/Views/dashboard/assessments/time-stats.svg';
import { ReactComponent as ExpiryStatsIcon } from '../../../assets/icons/Views/dashboard/assessments/expiry-stats.svg';
import { ReactComponent as InvitedStatsIcon } from '../../../assets/icons/Views/dashboard/assessments/invite-stats.svg';
import { ReactComponent as AttemptsStatsIcon } from '../../../assets/icons/Views/dashboard/assessments/attempt-stats.svg';
import { ReactComponent as GrayLineIcon } from '../../../assets/icons/Views/dashboard/test-detail/line.svg';
import Input from '../../../components/sub-component/Input';
import Button from '../../../components/sub-component/Button';
import Table from '../../../components/common/Table';
import TestDetailsRightView from './TestDetailsRightView';
import { useMediaQuery } from '@mui/material';
import { style } from '@mui/system';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAssessSpec,
	deleteAssessSpec,
	cloneAssessSpec,
	resetCurrAssessSpec,
} from '../../../redux/thunks/AssessSpec';
import { patchAssessmentHiringStage } from '../../../redux/thunks/Assessment';
import ProcessingIndicator from '../../../components/common/ProcessingIndicator';
import { PathName } from '../../../utils/routes';
import {
	DateToMMDDYYYY,
	getFormatedDate,
	timeDifference,
} from '../../../utils/utilities';
import AssessmentCriteriaModal from './AssessmentCriteriaModal';
import CustomModal from '../../../components/common/Modal';
import CustomTooltip from '../../../components/common/CustomTooltip';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import { inviteUsers } from '../../../redux/thunks/User';
import { CommonContext } from '../../../utils/contexts/CommonContext';
import { GetAvtarImage } from '../../../components/dashboard/AvtarBadge';
import AssessmentActionBtnComp from '../../../components/dashboard/assessments/AssessmentActionBtnComp';
import NavBar from '../../../components/common/NavBar';
import { ReactComponent as MinimizeIcon } from '../../../assets/icons/components/dashboard/minimize.svg';
import { ReactComponent as Search } from '../../../assets/icons/Views/candidate/assessments/search.svg';
import TestDetailedView from './TestDetailedView';
import { RequestStatus } from '../../../utils/Constants';
import AlertBox from '../../../components/common/AlertBox';
import { secondsTohhmm } from '../../../utils/utilities';
import { useFocusWithin } from '@react-aria/interactions';
import AssessmentCriteria from './AssessmentCriteria';

// const testData = [
// 	// [candidate , invitedOn , lastActivity , status , hiringStage , action],
// 	['Lauren Ipsum', 'December 12, 2019', 'December 12, 2019', <div key='1' className='tag1'>Tag</div>, <div key='1' className='hiring-stage'><Select readOnly options={[{ name: '1 - 50', value: 50 }, { name: '51 - 100', value: 100 }]} onSelection={(option) => { }} title={''} placeholder={'Hiring Stage'} titleStyle={{ color: '#6E6B7B' }} /></div>, <div key='1' className='d-flex flex-row align-items-center' style={{ gap: '12px' }}><EyeIcon /> <BlackTrashIcon /></div>],
// 	['Lauren Ipsum', 'December 12, 2019', 'December 12, 2019', <div key='1' className='tag1'>Tag</div>, <div key='1' className='hiring-stage'><Select readOnly options={[{ name: '1 - 50', value: 50 }, { name: '51 - 100', value: 100 }]} onSelection={(option) => { }} title={''} placeholder={'Hiring Stage'} titleStyle={{ color: '#6E6B7B' }} /></div>, <div key='1' className='d-flex flex-row align-items-center' style={{ gap: '12px' }}><EyeIcon /> <BlackTrashIcon /></div>],
// 	['Lauren Ipsum', 'December 12, 2019', 'December 12, 2019', <div key='1' className='tag1'>Tag</div>, <div key='1' className='hiring-stage'><Select readOnly options={[{ name: '1 - 50', value: 50 }, { name: '51 - 100', value: 100 }]} onSelection={(option) => { }} title={''} placeholder={'Hiring Stage'} titleStyle={{ color: '#6E6B7B' }} /></div>, <div key='1' className='d-flex flex-row align-items-center' style={{ gap: '12px' }}><EyeIcon /> <BlackTrashIcon /></div>],
// 	['Lauren Ipsum', 'December 12, 2019', 'December 12, 2019', <div key='1' className='tag1'>Tag</div>, <div key='1' className='hiring-stage'><Select readOnly options={[{ name: '1 - 50', value: 50 }, { name: '51 - 100', value: 100 }]} onSelection={(option) => { }} title={''} placeholder={'Hiring Stage'} titleStyle={{ color: '#6E6B7B' }} /></div>, <div key='1' className='d-flex flex-row align-items-center' style={{ gap: '12px' }}><EyeIcon /> <BlackTrashIcon /></div>],
// 	['Lauren Ipsum', 'December 12, 2019', 'December 12, 2019', <div key='1' className='tag1'>Tag</div>, <div key='1' className='hiring-stage'><Select readOnly options={[{ name: '1 - 50', value: 50 }, { name: '51 - 100', value: 100 }]} onSelection={(option) => { }} title={''} placeholder={'Hiring Stage'} titleStyle={{ color: '#6E6B7B' }} /></div>, <div key='1' className='d-flex flex-row align-items-center' style={{ gap: '12px' }}><EyeIcon /> <BlackTrashIcon /></div>],
// 	['Lauren Ipsum', 'December 12, 2019', 'December 12, 2019', <div key='1' className='tag1'>Tag</div>, <div key='1' className='hiring-stage'><Select readOnly options={[{ name: '1 - 50', value: 50 }, { name: '51 - 100', value: 100 }]} onSelection={(option) => { }} title={''} placeholder={'Hiring Stage'} titleStyle={{ color: '#6E6B7B' }} /></div>, <div key='1' className='d-flex flex-row align-items-center' style={{ gap: '12px' }}><EyeIcon /> <BlackTrashIcon /></div>],

// ];

export default function TestDetails(props) {
	const isOnSmallWebScreen = useMediaQuery(
		'(min-width:1199px) and (max-width:1320px)'
	);
	const isOnWebScreen = useMediaQuery('(min-width:1200px)');
	const isOnTabletScreen = useMediaQuery(
		'(min-width:768px) and (max-width:1199px)'
	);
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	//   const isOnMobileScreen = useMediaQuery("(max-width: 767px)");
	const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
	const [actionBtnOpen, setActionBtnOpen] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const [selectedRowIndex, setSelectedRowIndex] = useState();
	// const [isAssessmentCriteriaModalOpen, setIsAssessmentCriteriaModalOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState();

	const [testData, setTestData] = useState([]);

	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const assessSpec = useSelector((state) => state.assessSpecReducer);
	const users = useSelector((state) => state.userReducer);
	const auth = useSelector((state) => state.authReducer);
	const [alert, setAlert] = useState(null);
	const [isCandDetails, setIsCandDetails] = useState(true);

	const [curr_assessment_spec, setCurr_assessment_spec] = useState(
		assessSpec?.curr_assessment_spec
	);
	const { commonValues, setCommonValues } = useContext(CommonContext);

	const assesment = assessSpec?.data?.results?.find(
		(assessment) => assessment?.id === assessSpec?.curr_assessment_spec?.id
	);

	const defaultFilterOptions = [
		{ name: 'All Candidate(s)', value: 'all', count: 0 },
		{ name: 'Pending', value: 'PENDING', count: 0 },
		{ name: 'In Progress', value: 'IN_PROGRESS', count: 0 },
		{ name: 'Completed', value: 'FINISHED', count: 0 },
		{ name: 'Declined', value: 'DECLINED', count: 0 },
		{ name: 'Not Qualified', value: 'NOT_QUALIFIED', count: 0 },
	];

	const [navOptions, setNavOptions] = useState([]);
	const [selectedNavLink, setSelectedNavLink] = useState('all');
	const [customSearch, setCustomSearch] = useState();
	const [inviteModal, setInviteModal] = useState({
		isOpen: false,
		emailList: [],
	});
	const [deleteAssessmentModal, setDeleteAssessmentModal] = useState({
		isOpen: false,
	});

	const testTableHeader = [
		{
			id: 'name',
			name: 'Candidates',
			type: 'string',
			disableSorting: true,
			screens: ['web', 'tab', 'mob'],
		},
		{
			id: 'score',
			name: 'Score',
			type: 'string',
			disableSorting: false,
			screens: ['web', 'tab'],
		},
		{
			id: 'candidatesStatus',
			name: 'Status',
			type: 'component',
			disableSorting: true,
			enableFilter: true,
			filterName: 'status',
			filterOptions: [
				{ name: 'Pending', value: 'PENDING' },
				{ name: 'In Progress', value: 'IN_PROGRESS' },
				{ name: 'Completed', value: 'FINISHED' },
				{ name: 'Declined', value: 'DECLINED' },
				{ name: 'Not Qualified', value: 'NOT_QUALIFIED' },
				{ name: 'Expired', value: 'EXPIRED' },
			],
			screens: ['web', 'tab', 'mob'],
		},
		{
			id: 'created_at',
			name: 'Invited on',
			type: 'string',
			disableSorting: false,
			screens: ['web'],
			timeDifference: true
		},
		{
			id: 'updated_at',
			name: 'Last Activity',
			type: 'string',
			disableSorting: false,
			screens: ['web'],
			timeDifference: true
		},

		{
			id: 'hiringStage',
			name: 'Hiring Stage',
			type: 'string',
			disableSorting: true,
			enableFilter: true,
			filterName: 'hiring_stage',
			filterOptions: [
				{ name: 'Not Yet Evaluated', value: 'PENDING' },
				{ name: 'Rejected', value: 'REJECTED' },
				{ name: 'Shortlisted', value: 'SHORTLISTED' },
			],
			screens: ['web', 'tab'],
		},
		// { id: 'action', name: 'Actions', type: 'component', disableSorting: true, screens: ['web', 'tab',] },
	];

	const OnOpenRightSideBartestTableHeader = [
		{
			id: 'name',
			name: 'Candidates',
			type: 'string',
			disableSorting: true,
			screens: ['web', 'tab', 'mob'],
		},

		{
			id: 'hiringStage',
			name: 'Hiring Stage',
			type: 'string',
			disableSorting: true,
			enableFilter: true,
			filterName: 'hiring_stage',
			filterOptions: [
				{ name: 'Not Yet Evaluated', value: 'PENDING' },
				{ name: 'Rejected', value: 'REJECTED' },
				{ name: 'Shortlisted', value: 'SHORTLISTED' },
			],
			screens: ['web', 'tab'],
		},
		// { id: 'action', name: 'Actions', type: 'component', disableSorting: true, screens: ['web', 'tab',] },
	];

	const customSorting = {
		score: (a, b) => {
			const parseScore = (scoreString) => {
				if (scoreString === 'N/A') {
					return 0;
				}
				return parseFloat(scoreString.replace('%', ''));
			};
			return parseScore(a) - parseScore(b);
		},
		created_at: (a, b) => {
			const dateA = new Date(a);
			const dateB = new Date(b);
			return dateA - dateB;
		},
		updated_at: (a, b) => {
			const dateA = new Date(a);
			const dateB = new Date(b);
			return dateA - dateB;
		},
	};

	const searchColumns = ['name', 'email'];

	const handleRowSelect = (id, type, value) => {
		if (type === 'single') {
			setCurr_assessment_spec({
				...curr_assessment_spec,
				assessments: curr_assessment_spec.assessments.map((assessment) =>
					id === assessment.id
						? { ...assessment, selected: !assessment.selected }
						: assessment
				),
			});
		} else {
			setCurr_assessment_spec({
				...curr_assessment_spec,
				assessments: curr_assessment_spec.assessments.map((assessment, i) => {
					return id.includes(assessment.id)
						? { ...assessment, selected: value }
						: { ...assessment };
				}),
			});
		}
	};

	const handleBulkUpdate = async (selectedIds, hiringStage, assessments) => {
		try {
			await dispatch(
				patchAssessmentHiringStage({
					data: {
						assess_ids: selectedIds,
						spec_id: curr_assessment_spec.id,
						hiring_stage: hiringStage,
					},
				})
			);
			setCurr_assessment_spec({
				...assessSpec?.curr_assessment_spec,
				assessments: assessments,
			});
			setAlert(
				<AlertBox
					style={{ zIndex: '99999' }}
					color="success"
					description={'Bulk Hiring Stage Updated Successfully'}
					title="Success"
					isOpen={true}
					onCloseAlert={() => {
						setAlert(null);
					}}
				/>
			);
			const selectedAssessment = assessments?.find(
				(assessment) => assessment.id === selectedRow?.id
			);
			if (selectedAssessment) {
				setSelectedRow(selectedAssessment);
			}
		} catch (error) {
			setAlert(
				<AlertBox
					style={{ zIndex: '99999' }}
					color='danger'
					description={'Bulk Hiring Stage Update Failed'}
					title='Error'
					isOpen={true}
					onCloseAlert={() => {
						setAlert(null);
					}}
				/>
			);
		}
	};

	useMemo(() => {
		const assessments = assessSpec?.curr_assessment_spec?.assessments?.map(
			(assessment) => {
				return { ...assessment };
			}
		);
		setCurr_assessment_spec({
			...assessSpec?.curr_assessment_spec,
			assessments: assessments,
		});
	}, [assessSpec?.curr_assessment_spec]);

	useMemo(() => {
		if (assessSpec.curr_assessment_spec === null) return;
		const _data = curr_assessment_spec.assessments?.map((assessment, index) => {
			return {
				name: (
					<div
						style={{ marginLeft: '24px', marginTop: '2px' }}
						className='d-flex align-items-baseline'
					>
						{' '}
						{assessment.user?.first_name || assessment.user?.last_name
							? `${assessment.user?.first_name || ''}
					${assessment.user?.last_name || ''}`
							: `${assessment?.email || '-'}`}
					</div>
				),
				createdAt: assessment.created_at,
				updatedAt: assessment.updated_at,
				candidatesStatus: (
					<div
						key='1'
						className='candidate-status-container body-2'
						style={{
							border: assessment.status !== 'FINISHED' ? '1px solid black' : '',
							background: assessment.status === 'FINISHED' ? '#7C21FF' : '#FFFFFF',
							color: assessment.status === 'FINISHED' ? '#FFF' : '#121216',
						}}
					>
						{assessment.status === 'PENDING'
							? 'Pending'
							: assessment.status === 'IN_PROGRESS'
								? 'In Progress'
								: assessment.status === 'NOT_QUALIFIED'
									? 'Not Qualified'
									: assessment.status === 'DECLINED'
										? 'Declined'
										: assessment.status === 'EXPIRED'
											? 'EXPIRED'
											: 'Completed'}
					</div>
				),
				score: assessment.cum_score
					? assessment.cum_score === null
						? 'N/A'
						: `${Math.round(assessment.cum_score * 100)}%`
					: assessment?.cum_score_obj
						? `${parseInt((10 / 1) * assessment?.cum_score_obj?.score)}`
						: 'N/A',
				...assessment,
				hiringStage: `${assessment?.hiring_stage?.charAt(0).toUpperCase() +
					assessment?.hiring_stage?.toLowerCase()?.slice(1)
				}`,
				user: {
					name: `${assessment.user?.first_name || ''} ${assessment.user?.last_name || ''
					}`,
					email: assessment.email,
					...assessment.user,
				},
			};
		});
		setTestData(_data ? [..._data] : []);
	}, [curr_assessment_spec, curr_assessment_spec?.assessments]);

	useMemo(() => {
		if (assessSpec.curr_assessment_spec === null) return;
		const _navOptions = [];
		curr_assessment_spec?.assessments?.length > 0 &&
			defaultFilterOptions.map((navOp) => {
				if (navOp?.value === 'all') {
					return _navOptions.push({
						...navOp,
						count: curr_assessment_spec?.assessments?.length || 0,
					});
				} else {
					return _navOptions.push({
						...navOp,
						count:
							curr_assessment_spec?.assessments?.filter((_assess) => {
								return _assess.status === navOp?.value?.toUpperCase();
							}).length || 0,
					});
				}
			});
		setNavOptions(
			_navOptions.length > 0 ? [..._navOptions] : [...defaultFilterOptions]
		);
	}, [curr_assessment_spec]);

	const onUpdateSharedPublicly = (shared_publicly) => {
		if (assessSpec.curr_assessment_spec === null) return;
		var _assessments = curr_assessment_spec.assessments;
		let _assessment =
			_assessments.filter((_assess) => {
				return _assess?.id === selectedRow?.id;
			})[0] || selectedRow;
		_assessment = {
			..._assessment,
			shared_publicly: shared_publicly,
		};
		_assessments = _assessments.map((item) => {
			return item.id == selectedRow.id ? _assessment : item;
		});
		setSelectedRow(_assessment);
		setCurr_assessment_spec({
			...curr_assessment_spec,
			assessments: _assessments,
		});
	};

	const onHiringStageUpdate = (hiringStage) => {
		if (assessSpec.curr_assessment_spec === null) return;
		var _assessments = curr_assessment_spec.assessments;
		let _assessment =
			_assessments.filter((_assess) => {
				return _assess?.id === selectedRow?.id;
			})[0] || selectedRow;
		_assessment = {
			..._assessment,
			hiring_stage: hiringStage,
			hiringStage: hiringStage,
		};
		_assessments = _assessments.map((item) => {
			return item.id == selectedRow.id ? _assessment : item;
		});
		setSelectedRow(_assessment);
		setCurr_assessment_spec({
			...curr_assessment_spec,
			assessments: _assessments,
		});
	};

	const viewCriteria = async () => {
		history.push(`${PathName.ASSESSMENTS}/criteria/${params.id}`);
	};
	const cloneAssessment = async () => {
		// history.push(`${PathName.ASSESSMENTS}/criteria/${params.id}`);
		const res = await dispatch(cloneAssessSpec({ id: params.id }));
		if (res?.meta?.requestStatus === RequestStatus.REJECTED) {
			setAlert(
				<AlertBox
					style={{ zIndex: '99999' }}
					color='danger'
					description={res?.payload}
					title='Error'
					isOpen={true}
					onCloseAlert={() => {
						setAlert(null);
					}}
				/>
			);
		} else {
			setAlert(
				<AlertBox
					style={{ zIndex: '99999' }}
					color='success'
					description={`${assessSpec?.curr_assessment_spec?.title} cloned successfully!`}
					title='Success'
					isOpen={true}
					onCloseAlert={() => {
						setAlert(null);
					}}
				/>
			);
			history.push(`${PathName.ASSESSMENTS}/create/${res?.payload?.data?.id}`);
		}
	};

	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: (e) => {
			'';
		},
		onBlurWithin: (e) => {
			setActionBtnOpen(false);
		},
	});

	useEffect(() => {
		dispatch(getAssessSpec({ id: params.id, query: '?expand=assessments' }));
		setIsReady(true);
		return () => {
			setIsRightMenuOpen(false);
			setCommonValues({
				...commonValues,
				isSideBarExpanded: true,
				detailedTestView: { isExpanded: false, testType: null, data: null },
			});
		};
	}, []);

	if (
		assessSpec.loading ||
		!isReady ||
		assessSpec?.curr_assessment_spec === null
	) {
		return (
			<div
				className='d-flex align-items-center justify-comtent-center'
				style={{
					width: isOnMobileScreen ? '100vw' : 'calc(100vw - 40px)',
					height: '100vh',
				}}
			>
				<ProcessingIndicator className='medium-indicator' />
			</div>
		);
	}

	// if (assessSpec.curr_assessment_spec === null) {
	// 	return <Redirect to={PathName.ASSESSMENTS} />;
	// }

	const handleInviteCandidates = () => {
		if (inviteModal.emailList) {
			dispatch(
				inviteUsers({ spec_id: params.id, emails: inviteModal.emailList })
			);
		}
		setInviteModal({
			isOpen: false,
			emailList: [],
		});
	};

	const InviteTeamModal = () => {
		return (
			<CustomModal
				open={inviteModal.isOpen}
				title='Invite by email'
				handleCloseModal={() => {
					setInviteModal({
						isOpen: false,
						emailList: [],
					});
				}}
				onConfirm={() => {
					handleInviteCandidates();
				}}
				processing={users.processing}
				confirmBtnTitle={'Invite'}
				btnWidth={'144px'}
			>
				<div
					className='invite-email-public-assessment-container'
					style={{ marginTop: '40px' }}
				>
					<div className='row-invite-email-publish-assessment-container'>
						<div style={{ width: '1005' }}>
							<ReactMultiEmail
								placeholder='Email'
								emails={inviteModal.emailList}
								onChange={(_emails) => {
									setInviteModal({ ...inviteModal, emailList: _emails });
								}}
								validateEmail={(email) => {
									return isEmail(email); // return boolean
								}}
								getLabel={(email, index, removeEmail) => {
									return (
										<div data-tag key={index}>
											{email}
											<span data-tag-handle onClick={() => removeEmail(index)}>
												×
											</span>
										</div>
									);
								}}
							/>
						</div>
					</div>
					<span className='description semibold' style={{ color: '#96999C' }}>
						Use comma to add more than one email
					</span>
				</div>
			</CustomModal>
		);
	};

	const MinimizeViewBar = () => {
		return (
			<div className={'collapsed-left-sidebar-container pointer'}
				onClick={() => {
					setCommonValues({
						...commonValues,
						detailedTestView: {
							isExpanded: false,
							testType: null,
							data: null,
						},
					});
					(isOnTabletScreen || isOnMobileScreen) && setIsRightMenuOpen(false);
				}}
			>
				{isOnMobileScreen &&
					<span className='subtitle-1 sidebar-container-headline'>
						{assessSpec?.curr_assessment_spec?.title}
					</span>
				}
				<MinimizeIcon style={{ width: isOnMobileScreen ? '34' : '44px', height: isOnMobileScreen ? '34' : '44px' }}/>
				{!isOnMobileScreen &&
					<span className='headline-3 sidebar-container-headline'>
						{assessSpec?.curr_assessment_spec?.title}
					</span>
				}
			</div>
		);
	};

	return (
		<>
			{alert}
			{
				<>
					<CustomModal
						open={deleteAssessmentModal.isOpen}
						varrient='alert-warning-modal'
						processing={assessSpec.processing}
						title={`Delete Assessment`}
						description={'Are you sure you want to delete this assessment?'}
						dangerAlert={true}
						handleCloseModal={() => {
							setDeleteAssessmentModal({ isOpen: false });
						}}
						confirmBtnTitle={'Delete'}
						onConfirm={async () => {
							await dispatch(
								deleteAssessSpec({
									data: assessSpec?.curr_assessment_spec,
									id: assessSpec?.curr_assessment_spec?.id,
								})
							);
							setDeleteAssessmentModal({ isOpen: false });
							{
								assessSpec.curr_assessment_spec.status === 'ACTIVE'
									? history.push(`${PathName.ASSESSMENTS}`)
									: history.push(`${PathName.ASSESSMENTS}/archived`);
							}
						}}
					/>
				</>
			}
			{isOnWebScreen ? (
				<>
					<div
						className='view-with-sidebar'
						style={
							commonValues.detailedTestView.isExpanded
								? { justifyContent: 'flex-start', gap: '24px' ,overflow:'hidden'}
								: {overflow:'hidden'}
						}
					>
						{commonValues.detailedTestView.isExpanded ? (
							<MinimizeViewBar />
						) : (
							<div
								className={`left-sidebar-container ${isRightMenuOpen && ' left-sidebar-open '
								}`}
							>
								<div className='test-detail-container w-100 d-flex flex-column align-items-start hide-scrollbar' style={{height: 'calc(100vh - 49px)',overflowY:'auto'}}>
									<div
										className={`test-detail-header w-100 d-flex flex-row justify-content-between mb-3 ${isRightMenuOpen && ' test-detail-header'
										}`}
										style={
											{ height: isRightMenuOpen ? '214px' : '144px' }
										}
									>
										<div className='test-detail-header-left w-100 d-flex flex-column'>
											<div className=' d-flex justify-content-between' >

												<div style={{ height: '44px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '16px' }}>
													<div className='d-flex align-items-center justify-content-center pointer' style={{ width: '44px', height: '44px', background: '#F6F5F8', borderRadius: '50px' }} onClick={history.goBack}>
														<LeftArrowIcon />
													</div>
													<span className='body-2 ' style={{ color: '#808191' }}>Active Assessment</span>
												</div>
												<div
													className=' justify-content-between align-items-center'
													style={{ gap: '8px', display: isRightMenuOpen ? 'none' : 'flex' }}
												>
													<div className='icon-cover' onClick={() => { viewCriteria(); }} >
														<FileIcon />
													</div>
													{auth.data.role.name !== 'Member' && (
														<div className='icon-cover' onClick={() => { cloneAssessment(); }}>
															<CopyIcon />
														</div>
													)}
													{assessSpec.curr_assessment_spec.assessments.length <= 0 && (
														<div className='icon-cover' onClick={() => { setDeleteAssessmentModal({isOpen: true,}); }}>
															<TrashIcon />
														</div>
													)}
													<div
														style={{
															width: isRightMenuOpen && isOnSmallWebScreen ? '110px' : '171px',
														}}
													// className='share-candidate-btn'
													>
														{assessSpec.curr_assessment_spec.status ===
															'ACTIVE' && (
															<>
																{auth.data.role.name !== 'Member' && (
																	<Button
																		btn='ragular-btn'
																		varrient='primary'
																		// preIcon={<ShareIcon />}
																		title={isRightMenuOpen && isOnSmallWebScreen ? 'Invite' : 'Invite Candidates'}
																		style={{
																			width: '171px',
																			height: '44px',
																			background: '#121216',
																			borderRadius: '50px',
																			border: '0px'
																		}}
																		onClick={() => {
																			history.push({
																				pathname: `${PathName.ASSESSMENTS}/publish/${params.id}`,
																				state: { fromDetails: true },
																			});
																		}}
																	/>
																)}
															</>
														)}
													</div>

												</div>


											</div>
											{/* <GetAvtarImage
												className='assessment-upload-icon pointer'
												src={{
													image_id: assessSpec?.curr_assessment_spec?.avatar_id,
													name: assessSpec?.curr_assessment_spec?.title,
												}}
												size='custom'
												width={72}
												height={72}
											/> */}
											<div className='d-flex justify-content-between' >
												<div className='w-100 d-flex justify-content-between' style={{ gap: '8px', flexDirection: isRightMenuOpen ? 'column' : 'row', }}>
													<div
														className='w-100 d-flex justify-content-between'
														style={{ gap: '8px', flexDirection: isRightMenuOpen ? 'column' : 'row', }}
													>
														<span className='truncate-assessment-title headline-3 text-start' style={{ color: '#2B3340' }}>
															{assessSpec.curr_assessment_spec.title}
														</span>
														<div className='d-flex' style={{ gap: isRightMenuOpen ? '6px' : '24px', flexDirection: isRightMenuOpen ? 'column' : 'row', alignItems: isRightMenuOpen ? 'start' : 'center' }}>
															<div
																className='d-flex flex-row align-items-center' style={{ gap: '6px' }}

															>
																<CreatedIcon />
																<span className='body-2 black-500'>
																	{'Created on '}
																	<span
																		className='body-2'
																		style={{ color: '#121216', fontWeight: '500' }}
																	>
																		{DateToMMDDYYYY(
																			assessSpec.curr_assessment_spec.created_at
																		)}
																	</span>
																</span>
															</div>
															<div
																className='d-flex flex-row align-items-center'
																style={{ gap: '6px' }}
															>
																<img src={LastActivity} alt='' />
																<span className='body-2 black-500'>
																	{'Last Activity '}
																	<span
																		className='body-2'
																		style={{ color: '#121216', fontWeight: '500' }}
																	>
																		{timeDifference(
																			assessSpec.curr_assessment_spec.updated_at
																		)}{' '}

																	</span>
																</span>
																<CustomTooltip
																	position='top-start'
																	text={`on ${getFormatedDate(
																		assessSpec.curr_assessment_spec.updated_at
																	).replace('at', '/')}`}
																>
																	<InfoIcon
																		style={{
																			width: '16px',
																			height: '16px',
																			stroke: '#121216',
																		}}
																	/>
																</CustomTooltip>
															</div>
														</div>
														{/* {assessSpec.curr_assessment_spec.deadline && <div className='d-flex flex-row align-items-center' style={{ gap: '6px' }}>
																<CalendarIcon />
																<span className='body-2 black-500'>
																	{'Deadline '}
																	<span className='body-2' style={{ color: '#6F2DBD', fontWeight: '500' }}>
																		{(assessSpec.curr_assessment_spec.deadline / 86400)} Days
																	</span>
																</span>
															</div>} */}
													</div>

												</div>
												<div
													className=' justify-content-between'
													style={{ display: isRightMenuOpen ? 'flex' : 'none'}}
												>

													<div
														style={{
															// width: isRightMenuOpen && isOnSmallWebScreen ? '110px' : '150px',
															position: 'relative',
															// right: isRightMenuOpen ? '0px' : '225px',
															// top: isRightMenuOpen ? '60px' : '0px',
														}}
														tabIndex={-1}
														{...focusWithinProps}
													>
													
													</div>
													<div
														style={{
															display:'flex',
															gap:'16px'

														}}
													// className='share-candidate-btn'
													>
														<div className='icon-cover' onClick={() => { viewCriteria(); }} >
															<FileIcon />
														</div>
														{auth.data.role.name !== 'Member' && (
															<div className='icon-cover' onClick={() => { cloneAssessment(); }}>
																<CopyIcon />
															</div>
														)}
														{assessSpec.curr_assessment_spec.assessments.length <= 0 && (
															<div className='icon-cover' onClick={() => { setDeleteAssessmentModal({isOpen: true,}); }}>
																<TrashIcon />
															</div>
														)}
														{assessSpec.curr_assessment_spec.status ===
															'ACTIVE' && (
															<>
																{auth.data.role.name !== 'Member' && (
																	<Button
																		btn='ragular-btn'
																		varrient='primary'
																		// preIcon={<ShareIcon />}
																		// title={isRightMenuOpen && isOnSmallWebScreen ? 'Share' : 'Share with Candidates'}
																		title={
																			<span style={{ color: 'white' }}>
																				Invite Candidates
																			</span>
																		}
																		style={{
																			width: '171px',
																			height: '44px',
																			background: '#121216',
																			borderRadius: '50px',
																			border: '0px'
																		}}
																		onClick={() => {
																			history.push({
																				pathname: `${PathName.ASSESSMENTS}/publish/${params.id}`,
																				state: { fromDetails: true },
																			});
																		}}
																	/>
																)}
															</>
														)}
													</div>

												</div>
											</div>
										</div>
									</div>
									<div className='test-detail-assessments-stats-container mb-3'>
										<div className='assessments-stats'>
											<div
												className={`assessments-stats-card ${isRightMenuOpen ? 'candidate-assess-stats-card' : ''}`}
												style={isRightMenuOpen ? { width: '22%' } : {}}
											>
												<div className='stats-card-text'>
													<div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
														<CustomTooltip
															customPosition={{ bottom: '42px', right: '5px' }}
															text={
																'Assessment Duration'
															}
														>
															<span className='body-2  dark-50' style={{ color: '#808191', display: isRightMenuOpen ? 'none' : 'block' }}>
																Total
															</span>
														</CustomTooltip>
														<CustomTooltip
															customPosition={{ bottom: '12px', left: '8px' }}
															text={
																assessSpec.curr_assessment_spec
																	.objective_test_duration !== undefined ||
																	assessSpec.curr_assessment_spec
																		.objective_test_duration !== null
																	? 'This cumulative duration applies to objective tests (skill, coding, value fitness). Text and Case Study tests have flexible time (hours to days), while Video and Upload tests are without set time limits.'
																	: 'This is the cumulative duration for all test types including Case Study and Text Responses'
															}
														>
															<InfoIcon
																style={{
																	position: 'absolute',
																	right: '0px',
																	bottom: '4px',
																	width: '14px',
																	height: '14px',
																	stroke: '#808191',
																	display: isRightMenuOpen ? 'none' : 'block'
																}}
															/>
														</CustomTooltip>
													</div>
													<span className={`headline-5 dark-100 ${isRightMenuOpen ? 'body-2' : ''
													}`}>
														{secondsTohhmm(
															assessSpec.curr_assessment_spec
																.objective_test_duration ||
															assessSpec.curr_assessment_spec.duration ||
															0
														)}
													</span>
												</div>
												<div className='AssessmentsStatsIcon-container'>
													<TimeStatsIcon className='AssessmentsStatsIcon'/>
												</div>
											</div>
											<div
												className={`assessments-stats-card ${isRightMenuOpen ? 'candidate-assess-stats-card' : ''}`}
												style={ isRightMenuOpen ? { width: '22%' } : {}}
											>
												<div className='stats-card-text'>
													<span className='body-2 dark-50' style={{ color: '#808191', display: isRightMenuOpen ? 'none' : 'block' }}>
														Expiry
													</span>
													<span className={`headline-5 dark-100 ${isRightMenuOpen ? 'body-2' : ''}`}>
														{assessSpec.curr_assessment_spec.deadline
															? `In ${assessSpec.curr_assessment_spec.deadline /
															86400
															} Days`
															: 'None'}
													</span>
												</div>
												<div className='AssessmentsStatsIcon-container'>
													<ExpiryStatsIcon className='AssessmentsStatsIcon'/>
												</div>
											</div>
											<div
												className={`assessments-stats-card ${isRightMenuOpen ? 'candidate-assess-stats-card' : ''}`}
												style={isRightMenuOpen ? { width: '22%' } : {}}
											>
												<div className='stats-card-text' >
													<span className='body-2  dark-50' style={{ color: '#808191', display: isRightMenuOpen ? 'none' : 'block' }}>
														Invites Sent
													</span>
													<span className={`headline-5 dark-100 ${isRightMenuOpen ? 'body-2' : ''}`}>
														{assesment?.invited || 0}
													</span>
												</div>
												<div className='AssessmentsStatsIcon-container'>
													<InvitedStatsIcon className='AssessmentsStatsIcon'/>
												</div>
											</div>
											<div
												className={`assessments-stats-card ${isRightMenuOpen ? 'candidate-assess-stats-card' : ''}`}
												style={isRightMenuOpen ? { width: '22%' } : {}}
											>
												<div className='stats-card-text'>
													<span className='body-2  dark-50' style={{ color: '#808191', display: isRightMenuOpen ? 'none' : 'block' }}>
														Attempts
													</span>
													<span className={`headline-5 dark-100 ${isRightMenuOpen ? 'body-2' : ''}`}>
														{assesment?.completed || 0}
													</span>
												</div>
												<div className='AssessmentsStatsIcon-container'>
													<AttemptsStatsIcon className='AssessmentsStatsIcon'/>
												</div>
											</div>
										</div>
									</div>
									<div style={{background:'white',borderRadius:'24px',width:'100%'}}>
										{/* <div className='assessment-criteria-heading' style={{padding:'34px 24px 24px 24px'}}>
											<div className='d-flex' style={{gap:'16px'}}>
												<div className={`assessment-criteria-heading-type ${isCandDetails && 'assessment-criteria-heading-type-selected'}`} onClick={() => setIsCandDetails(true)}>
													<span className={`body-2`}>Candidates</span>
												</div>
												<div className={`assessment-criteria-heading-type ${!isCandDetails && 'assessment-criteria-heading-type-selected'}`} onClick={() => setIsCandDetails(false)}>
													<span className={`body-2`}>Criteria</span>
												</div>
											</div>
											<div className='assessments-search-bar' >
												<div className='d-flex' style={{ gap: '0.5em' }}>
													<div className='search' style={{background:'#F6F5F8'}}>
														<Search height={'24px'} width={'24px'} />
														<Input
															inputStyle={{
																background: 'transparent',
																border: 'none',
															}}
															className={'w-100'}
															placeholder={'Search ...'}
															onChange={(e) => {
																setCustomSearch(e);
															}}
														/>
													</div>
												</div>
											</div>
										</div>
										{!isCandDetails ?
											<AssessmentCriteria assessSpec={assessSpec} />
											:
										} */}
										<div className='candidate-details-container'>
											<div className='w-100' style={{height: 'calc(100vh - 325px)',borderRadius:'24px',background:'white'}}>
												<Table
													headers={
														isRightMenuOpen
															? OnOpenRightSideBartestTableHeader
															: testTableHeader
													}
													// data={selectedNavLink === 'all' ? testData : testData.filter((_test) => { return (_test.status?.toLowerCase() === selectedNavLink?.toLowerCase()); })}
													data={testData}
													setData={setTestData}
													onSelectRow={(row) => {
														setIsRightMenuOpen(true);
														setCommonValues({
															...commonValues,
															isSideBarExpanded: false,
														});
														setSelectedRow(row);
													}}
													searchColumns={searchColumns}
													// disableSearchInput={true}
													customSorting={customSorting}
													enableCheckbox={true}
													highlightActiveRow
													testDetails={true}
													customSearch={false}
													handleBulkUpdate={handleBulkUpdate}
													nullTableTitle={'No Assessments to show'}
													nullTableDescription={`Try adjusting your search query or filters`}
													handleRowSelect={handleRowSelect}
													selectedId={selectedRow?.id}
												></Table>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						{
							// isRightMenuOpen &&
							<div
								className={`right-sidebar-container hide-scrollbar
									${isRightMenuOpen && ' test-details-right-sidebar-open '}
									${commonValues.detailedTestView.isExpanded && ' test-details-right-sidebar-open-minimized '}`
								}
							>
								<TestDetailsRightView
									onClose={() => {
										setIsRightMenuOpen(false);
										setCommonValues({
											...commonValues,
											isSideBarExpanded: true,
										});
									}}
									assessment={selectedRow}
									onUpdateSharedPublicly={onUpdateSharedPublicly}
									onHiringStageUpdate={onHiringStageUpdate}
									assessmentsLength={testData.length}
									selectedIndex={testData.findIndex(
										(test) => test.id === selectedRow?.id
									)}
									onSwitchAssessment={(next = false) => {
										const index = testData.findIndex(
											(test) => test.id === selectedRow?.id
										);
										if (index !== -1) {
											if (next && testData[index + 1]) {
												setSelectedRow(testData[index + 1]);
											} else if (testData[index - 1]) {
												setSelectedRow(testData[index - 1]);
											}
										}
									}}
								/>
							</div>
						}
						<TestDetailedView assessment={selectedRow} />
					</div>
				</>
			) : isOnTabletScreen ? (
				<>
					<div className='view-with-sidebar'>
						{isRightMenuOpen ? (
							<MinimizeViewBar />
						) : (
							<div className='test-detail-container hide-scrollbar' style={{height: 'calc(100vh - 49px)'}}>
								<div
									className={`test-detail-header w-100 d-flex flex-row justify-content-between mb-3 ${isRightMenuOpen && ' test-detail-header'
									}`}
									style={
										{ height: isRightMenuOpen ? '214px' : '144px' }
									}
								>
									<div className='test-detail-header-left w-100 d-flex flex-column'>
										<div className=' d-flex justify-content-between' >

											<div style={{ height: '44px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '16px' }}>
												<div className='d-flex align-items-center justify-content-center pointer' style={{ width: '44px', height: '44px', background: '#F6F5F8', borderRadius: '50px' }} onClick={history.goBack}>
													<LeftArrowIcon />
												</div>
												<span className='body-2 ' style={{ color: '#808191' }}>Active Assessment</span>
											</div>
											<div
												className=' justify-content-between align-items-center'
												style={{ gap: '8px', display: isRightMenuOpen ? 'none' : 'flex' }}
											>
												<div className='icon-cover' onClick={() => { viewCriteria(); }} >
													<FileIcon />
												</div>
												{auth.data.role.name !== 'Member' && (
													<div className='icon-cover' onClick={() => { cloneAssessment(); }}>
														<CopyIcon />
													</div>
												)}
												{assessSpec.curr_assessment_spec.assessments.length <= 0 && (
													<div className='icon-cover' onClick={() => { setDeleteAssessmentModal({isOpen: true,}); }}>
														<TrashIcon />
													</div>
												)}
												<div
													style={{
														width: isRightMenuOpen && isOnSmallWebScreen ? '110px' : '171px',
														// position: 'absolute',
														// right: '0px',
														// top: '0px',

													}}
													// className='share-candidate-btn'
												>
													{assessSpec.curr_assessment_spec.status ===
															'ACTIVE' && (
														<>
															{auth.data.role.name !== 'Member' && (
																<Button
																	btn='ragular-btn'
																	varrient='primary'
																	// preIcon={<ShareIcon />}
																	title={isRightMenuOpen && isOnSmallWebScreen ? 'Invite' : 'Invite Candidates'}
																	style={{
																		width: '171px',
																		height: '44px',
																		background: '#121216',
																		borderRadius: '50px',
																		border: '0px'
																	}}
																	onClick={() => {
																		history.push({
																			pathname: `${PathName.ASSESSMENTS}/publish/${params.id}`,
																			state: { fromDetails: true },
																		});
																	}}
																/>
															)}
														</>
													)}
												</div>

											</div>


										</div>
										{/* <GetAvtarImage
												className='assessment-upload-icon pointer'
												src={{
													image_id: assessSpec?.curr_assessment_spec?.avatar_id,
													name: assessSpec?.curr_assessment_spec?.title,
												}}
												size='custom'
												width={72}
												height={72}
											/> */}
										<div className='d-flex justify-content-between' >
											<div className='w-100 d-flex justify-content-between' style={{ gap: '8px', flexDirection: isRightMenuOpen ? 'column' : 'row', }}>
												<div
													className='w-100 d-flex justify-content-between'
													style={{ gap: '8px', flexDirection: isRightMenuOpen ? 'column' : 'row', }}
												>
													<span className='truncate-assessment-title headline-3 text-start' style={{ color: '#2B3340' }}>
														{assessSpec.curr_assessment_spec.title}
													</span>
													<div className='d-flex' style={{ gap: isRightMenuOpen ? '6px' : '24px', flexDirection: isRightMenuOpen ? 'column' : 'row', alignItems: isRightMenuOpen ? 'start' : 'center' }}>
														<div
															className='d-flex flex-row align-items-center' style={{ gap: '6px' }}

														>
															<CreatedIcon />
															<span className='body-2 black-500'>
																{'Created on '}
																<span
																	className='body-2'
																	style={{ color: '#121216', fontWeight: '500' }}
																>
																	{DateToMMDDYYYY(
																		assessSpec.curr_assessment_spec.created_at
																	)}
																</span>
															</span>
														</div>
														<div
															className='d-flex flex-row align-items-center'
															style={{ gap: '6px' }}
														>
															<img src={LastActivity} alt='' />
															<span className='body-2 black-500'>
																{'Last Activity '}
																<span
																	className='body-2'
																	style={{ color: '#121216', fontWeight: '500' }}
																>
																	{timeDifference(
																		assessSpec.curr_assessment_spec.updated_at
																	)}{' '}

																</span>
															</span>
															<CustomTooltip
																position='top-start'
																text={`on ${getFormatedDate(
																	assessSpec.curr_assessment_spec.updated_at
																).replace('at', '/')}`}
															>
																<InfoIcon
																	style={{
																		width: '16px',
																		height: '16px',
																		stroke: '#121216',
																	}}
																/>
															</CustomTooltip>
														</div>
													</div>
													{/* {assessSpec.curr_assessment_spec.deadline && <div className='d-flex flex-row align-items-center' style={{ gap: '6px' }}>
																<CalendarIcon />
																<span className='body-2 black-500'>
																	{'Deadline '}
																	<span className='body-2' style={{ color: '#6F2DBD', fontWeight: '500' }}>
																		{(assessSpec.curr_assessment_spec.deadline / 86400)} Days
																	</span>
																</span>
															</div>} */}
												</div>

											</div>
											<div
												className=' justify-content-between'
												style={{ gap: '16px', width: '436px', display: isRightMenuOpen ? 'flex' : 'none', flexDirection: isRightMenuOpen ? 'column' : 'row', alignItems: 'end', }}
											>

												<div
													style={{
														// width: isRightMenuOpen && isOnSmallWebScreen ? '110px' : '150px',
														position: 'relative',
														// right: isRightMenuOpen ? '0px' : '225px',
														// top: isRightMenuOpen ? '60px' : '0px',
													}}
													tabIndex={-1}
													{...focusWithinProps}
												>
													<Button
														btn='ragular-btn'
														varrient='secondary-btn'
														active={actionBtnOpen}
														preIcon={
															actionBtnOpen ? (
																<ArrowDown
																	style={{
																		transform: 'rotate(180deg)',
																		stroke: '#FFFFFF',
																	}}
																/>
															) : (
																<ArrowDown />
															)
														}
														title={
															<span style={{ color: 'white' }}>
																{isRightMenuOpen && isOnSmallWebScreen ? 'Options' : 'More Options'}
															</span>
														}
														style={{
															width: '209px',
															height: '44px',
															background: '#121216',
															borderRadius: '50px'
														}}
														onClick={() => setActionBtnOpen(!actionBtnOpen)}
													/>
													{actionBtnOpen && (
														<div className='action-btn-card d-flex flex-column'>
															<span
																className='action-btn-item body-4 black-700 text-start pointer'
																onClick={() => {
																	history.push(
																		`${PathName.ASSESSMENTS}/criteria/${params.id}`
																	);
																}}
															>
																	View Criteria
															</span>
															{auth.data.role.name !== 'Member' && (
																<>
																	<span
																		className='action-btn-item body-4 black-700 text-start pointer'
																		onClick={() => {
																			cloneAssessment();
																		}}
																	>
																			Clone Assessment
																	</span>
																	{assessSpec.curr_assessment_spec.assessments
																		.length <= 0 && (
																		<span
																			className='action-btn-item body-4 black-700 text-start pointer'
																			onClick={() => {
																				setDeleteAssessmentModal({
																					isOpen: true,
																				});
																			}}
																		>
																					Delete Assessment
																		</span>
																	)}
																</>
															)}
														</div>
													)}
												</div>
												<div
													style={{
														width: isRightMenuOpen && isOnSmallWebScreen ? '110px' : '171px',
														// position: 'absolute',
														// right: '0px',
														// top: '0px',

													}}
													// className='share-candidate-btn'
												>
													{assessSpec.curr_assessment_spec.status ===
															'ACTIVE' && (
														<>
															{auth.data.role.name !== 'Member' && (
																<Button
																	btn='ragular-btn'
																	varrient='primary'
																	// preIcon={<ShareIcon />}
																	title={isRightMenuOpen && isOnSmallWebScreen ? 'Invite' : 'Invite Candidates'}
																	style={{
																		width: '171px',
																		height: '44px',
																		background: '#121216',
																		borderRadius: '50px',
																		border: '0px'
																	}}
																	onClick={() => {
																		history.push({
																			pathname: `${PathName.ASSESSMENTS}/publish/${params.id}`,
																			state: { fromDetails: true },
																		});
																	}}
																/>
															)}
														</>
													)}
												</div>

											</div>
										</div>
									</div>
								</div>
								<div className='test-detail-assessments-stats-container mb-3'>
									<div className='assessments-stats'>
										<div
											className={`assessments-stats-card ${isRightMenuOpen ? 'candidate-assess-stats-card' : ''
											}`}
											style={
												isRightMenuOpen ? { width: '22.5%', gap: '1.5em' } : {}
											}
										>
											<div className='stats-card-text'>
												<div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
													<CustomTooltip
														customPosition={{ bottom: '42px', right: '5px' }}
														text={
															'Assessment Duration'
														}
													>
														<span className='body-2  dark-50' style={{ color: '#808191', display: isRightMenuOpen ? 'none' : 'block' }}>
																Total
														</span>
													</CustomTooltip>

													<CustomTooltip
														customPosition={{ bottom: '12px', left: '8px' }}
														text={
															assessSpec.curr_assessment_spec
																.objective_test_duration !== undefined ||
																	assessSpec.curr_assessment_spec
																		.objective_test_duration !== null
																? 'This cumulative duration applies to objective tests (skill, coding, value fitness). Text and Case Study tests have flexible time (hours to days), while Video and Upload tests are without set time limits.'
																: 'This is the cumulative duration for all test types including Case Study and Text Responses'
														}
													>
														<InfoIcon
															style={{
																position: 'absolute',
																right: '0px',
																bottom: '4px',
																width: '14px',
																height: '14px',
																stroke: '#808191',
																display: isRightMenuOpen ? 'none' : 'block'
															}}
														/>
													</CustomTooltip>
												</div>
												<span className={`headline-5 dark-100 ${isRightMenuOpen ? 'body-2' : ''
												}`}>
													{secondsTohhmm(
														assessSpec.curr_assessment_spec
															.objective_test_duration ||
															assessSpec.curr_assessment_spec.duration ||
															0
													)}
												</span>
											</div>
											<div className='AssessmentsStatsIcon-container'>
												<TimeStatsIcon className='AssessmentsStatsIcon'/>
											</div>
										</div>
										<div
											className={`assessments-stats-card ${isRightMenuOpen ? 'candidate-assess-stats-card' : ''
											}`}
											style={
												isRightMenuOpen ? { width: '22.5%', gap: '1.5em' } : {}
											}
										>

											<div className='stats-card-text'>
												<span className='body-2 dark-50' style={{ color: '#808191', display: isRightMenuOpen ? 'none' : 'block' }}>
														Expiry
												</span>
												<span className={`headline-5 dark-100 ${isRightMenuOpen ? 'body-2' : ''
												}`}>
													{assessSpec.curr_assessment_spec.deadline
														? `In ${assessSpec.curr_assessment_spec.deadline /
															86400
														} Days`
														: 'None'}
												</span>
											</div>
											<div className='AssessmentsStatsIcon-container'>
												<ExpiryStatsIcon className='AssessmentsStatsIcon'/>
											</div>
										</div>
										<div
											className={`assessments-stats-card ${isRightMenuOpen ? 'candidate-assess-stats-card' : ''
											}`}
											style={
												isRightMenuOpen ? { width: '22.5%', gap: '1.5em' } : {}
											}
										>

											<div className='stats-card-text' >
												<span className='body-2  dark-50' style={{ color: '#808191', display: isRightMenuOpen ? 'none' : 'block' }}>
														Invites Sent
												</span>
												<span className={`headline-5 dark-100 ${isRightMenuOpen ? 'body-2' : ''
												}`}>
													{assesment?.invited || 0}
												</span>
											</div>
											<div className='AssessmentsStatsIcon-container'>
												<InvitedStatsIcon className='AssessmentsStatsIcon'/>
											</div>
										</div>
										<div
											className={`assessments-stats-card ${isRightMenuOpen ? 'candidate-assess-stats-card' : ''
											}`}
											style={
												isRightMenuOpen ? { width: '22.5%', gap: '1.5em' } : {}
											}
										>

											<div className='stats-card-text'>
												<span className='body-2  dark-50' style={{ color: '#808191', display: isRightMenuOpen ? 'none' : 'block' }}>
														Attempts
												</span>
												<span className={`headline-5 dark-100 ${isRightMenuOpen ? 'body-2' : ''
												}`}>
													{assesment?.completed || 0}
												</span>
											</div>
											<div className='AssessmentsStatsIcon-container'>
												<AttemptsStatsIcon className='AssessmentsStatsIcon'/>
											</div>
										</div>
									</div>
								</div>
								<div style={{background:'white',borderRadius:'24px'}}>
									{/* <div className='assessment-criteria-heading' style={{padding:'34px 24px 24px 24px'}}>
										<div className='d-flex' style={{gap:'16px'}}>
											<div className={`assessment-criteria-heading-type ${isCandDetails && 'assessment-criteria-heading-type-selected'}`} onClick={() => setIsCandDetails(true)}>
												<span className={`body-2`}>Candidates</span>
											</div>
											<div className={`assessment-criteria-heading-type ${!isCandDetails && 'assessment-criteria-heading-type-selected'}`} onClick={() => setIsCandDetails(false)}>
												<span className={`body-2`}>Criteria</span>
											</div>
										</div>
										<div className='assessments-search-bar' >
											<div className='d-flex' style={{ gap: '0.5em' }}>
												<div className='search' style={{background:'#F6F5F8'}}>
													<Search height={'24px'} width={'24px'} />
													<Input
														inputStyle={{
															background: 'transparent',
															border: 'none',
														}}
														className={'w-100'}
														placeholder={'Search ...'}
														onChange={(e) => {
															setCustomSearch(e);
														}}
													/>
												</div>
											</div>
										</div>
									</div>
									{!isCandDetails ?
										<AssessmentCriteria assessSpec={assessSpec} />
										:
									} */}
									<div className='candidate-details-container'>
										<div className='w-100' style={{height: 'calc(100vh - 325px)',borderRadius:'24px',background:'white'}}>
											<Table
												headers={
													isRightMenuOpen
														? OnOpenRightSideBartestTableHeader
														: testTableHeader
												}
												// data={selectedNavLink === 'all' ? testData : testData.filter((_test) => { return (_test.status?.toLowerCase() === selectedNavLink?.toLowerCase()); })}
												data={testData}
												setData={setTestData}
												onSelectRow={(row) => {
													setIsRightMenuOpen(true);
													setCommonValues({
														...commonValues,
														isSideBarExpanded: false,
													});
													setSelectedRow(row);
												}}
												searchColumns={searchColumns}
												// disableSearchInput={true}
												customSorting={customSorting}
												enableCheckbox={true}
												highlightActiveRow
												testDetails={true}
												customSearch={false}
												handleBulkUpdate={handleBulkUpdate}
												nullTableTitle={'No Assessments to show'}
												nullTableDescription={`Try adjusting your search query or filters`}
												handleRowSelect={handleRowSelect}
												selectedId={selectedRow?.id}
											></Table>
										</div>
									</div>
								</div>
							</div>
						)}
						{isRightMenuOpen && (
							<div
								className={`right-sidebar-container hide-scrollbar ${isRightMenuOpen && ' test-details-right-sidebar-open '
								} ${commonValues.detailedTestView.isExpanded &&
									' test-details-right-sidebar-open-minimized '
								}`}
							>
								{commonValues?.detailedTestView?.isExpanded === false ? (
									<TestDetailsRightView
										onClose={() => {
											setIsRightMenuOpen(false);
										}}
										assessment={selectedRow}
										onUpdateSharedPublicly={onUpdateSharedPublicly}
										onHiringStageUpdate={onHiringStageUpdate}
										assessmentsLength={testData.length}
										selectedIndex={testData.findIndex(
											(test) => test.id === selectedRow?.id
										)}
										onSwitchAssessment={(next = false) => {
											const index = testData.findIndex(
												(test) => test.id === selectedRow?.id
											);
											if (index !== -1) {
												if (next && testData[index + 1]) {
													setSelectedRow(testData[index + 1]);
												} else if (testData[index - 1]) {
													setSelectedRow(testData[index - 1]);
												}
											}
										}}
									/>
								) : (
									<TestDetailedView assessment={selectedRow} />
								)}
							</div>
						)}
					</div>
				</>
			) : (
				<>
					<div className='test-detail-page-container hide-scrollbar'>
						<div className='test-detail-page-content'>
							{!isRightMenuOpen ? (
								<div className='view-with-sidebar'>
									<div className='test-detail-container hide-scrollbar' style={{height: 'calc(100vh - 49px)'}}>
										<div
											className={`test-detail-header w-100 d-flex flex-row justify-content-between mb-3 ${isRightMenuOpen && ' test-detail-header'
											}`}
											style={
												{ height: 'max-content' }
											}
										>
											<div className='test-detail-header-left w-100 d-flex flex-column'>
												<div className=' d-flex flex-column justify-content-between' >

													<div style={{ height: '44px', display: 'flex', alignItems: 'center', gap: '16px' }}>
														<div className='d-flex align-items-center justify-content-center pointer' style={{ width: '34px', height: '34px', background: '#F6F5F8', borderRadius: '50px' }} onClick={history.goBack}>
															<LeftArrowIcon />
														</div>
														<span className='body-2 ' style={{ color: '#808191' }}>Active Assessment</span>
													</div>
													<div className=' mt-3'>
														<div className='w-100 d-flex justify-content-between mb-2'>
															<span className='truncate-assessment-title headline-3 text-start' style={{ color: '#2B3340' }}>
																{assessSpec.curr_assessment_spec.title}
															</span>
															<div className='d-flex' style={{gap:'8px'}}>
																<div className='icon-cover' onClick={() => { viewCriteria(); }} >
																	<FileIcon />
																</div>
																{auth.data.role.name !== 'Member' && (
																	<div className='icon-cover' onClick={() => { cloneAssessment(); }}>
																		<CopyIcon />
																	</div>
																)}
																{assessSpec.curr_assessment_spec.assessments.length <= 0 && (
																	<div className='icon-cover' onClick={() => { setDeleteAssessmentModal({isOpen: true,}); }}>
																		<TrashIcon/>
																	</div>
																)}
																{assessSpec.curr_assessment_spec.status === 'ACTIVE' && (
																	<>
																		{auth.data.role.name !== 'Member' && (
																			<div 
																				onClick={() => {
																					history.push({
																						pathname: `${PathName.ASSESSMENTS}/publish/${params.id}`,
																						state: { fromDetails: true },
																					});
																				}}	
																				className='d-flex align-items-center justify-content-center'
																				style={{
																					height: '34px',
																					width:'34px',
																					background: '#121216',
																					borderRadius:'50px',
																					// border:'1px solid black'
																				}}>
																				<ShareIcon  	/>
																			</div>
																	
																
																		)}
																	</>
																)}
															
															</div>
														</div>
														<div className='w-100 d-flex justify-content-between'>
															<div
																className='w-100 d-flex justify-content-between'
																style={{ gap: '8px', flexDirection: isRightMenuOpen ? 'column' : 'row', }}
															>
																<div className='d-flex flex-column' style={{ gap: '6px' }}>
																	<div
																		className='d-flex flex-row align-items-center' style={{ gap: '6px' }}

																	>
																		<CreatedIcon />
																		<span className='body-2 black-500'>
																			{'Created on '}
																			<span
																				className='body-2'
																				style={{ color: '#121216', fontWeight: '500' }}
																			>
																				{DateToMMDDYYYY(
																					assessSpec.curr_assessment_spec.created_at
																				)}
																			</span>
																		</span>
																	</div>
																	<div
																		className='d-flex flex-row align-items-center'
																		style={{ gap: '6px' }}
																	>
																		<img src={LastActivity} alt='' />
																		<span className='body-2 black-500'>
																			{'Last Activity '}
																			<span
																				className='body-2'
																				style={{ color: '#121216', fontWeight: '500' }}
																			>
																				{timeDifference(
																					assessSpec.curr_assessment_spec.updated_at
																				)}{' '}

																			</span>
																		</span>
																		<CustomTooltip
																			position='top-start'
																			text={`on ${getFormatedDate(
																				assessSpec.curr_assessment_spec.updated_at
																			).replace('at', '/')}`}
																		>
																			<InfoIcon
																				style={{
																					width: '16px',
																					height: '16px',
																					stroke: '#121216',
																				}}
																			/>
																		</CustomTooltip>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='test-detail-assessments-stats-container mb-3'>
											<div className='assessments-stats'>
												<div
													className={`assessments-stats-card ${isRightMenuOpen ? 'candidate-assess-stats-card' : ''}`}
													style={isRightMenuOpen ? { width: '22.5%', gap: '1.5em' } : {}}
												>
													<div className='stats-card-text'>
														<div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
															<CustomTooltip
																customPosition={{ bottom: '42px', right: '5px' }}
																text={
																	'Assessment Duration'
																}
															>
																<span className='body-2  dark-50' style={{ color: '#808191', display: isRightMenuOpen ? 'none' : 'block' }}>
																Total
																</span>
															</CustomTooltip>
															<CustomTooltip
																customPosition={{ bottom: '12px', left: '8px' }}
																text={
																	assessSpec.curr_assessment_spec
																		.objective_test_duration !== undefined ||
																	assessSpec.curr_assessment_spec
																		.objective_test_duration !== null
																		? 'This cumulative duration applies to objective tests (skill, coding, value fitness). Text and Case Study tests have flexible time (hours to days), while Video and Upload tests are without set time limits.'
																		: 'This is the cumulative duration for all test types including Case Study and Text Responses'
																}
															>
																<InfoIcon
																	style={{
																		position: 'absolute',
																		right: '0px',
																		bottom: '4px',
																		width: '14px',
																		height: '14px',
																		stroke: '#808191',
																		display: isRightMenuOpen ? 'none' : 'block'
																	}}
																/>
															</CustomTooltip>
														</div>
														<span className={`headline-5 dark-100 ${isRightMenuOpen ? 'body-2' : ''
														}`}>
															{secondsTohhmm(
																assessSpec.curr_assessment_spec
																	.objective_test_duration ||
															assessSpec.curr_assessment_spec.duration ||
															0
															)}
														</span>
													</div>
													<div className='AssessmentsStatsIcon-container'>
														<TimeStatsIcon className='AssessmentsStatsIcon'/>
													</div>
												</div>
												<div
													className={`assessments-stats-card ${isRightMenuOpen ? 'candidate-assess-stats-card' : ''
													}`}
													style={
														isRightMenuOpen ? { width: '22.5%', gap: '1.5em' } : {}
													}
												>
													<div className='stats-card-text'>
														<span className='body-2 dark-50' style={{ color: '#808191', display: isRightMenuOpen ? 'none' : 'block' }}>
														Expiry
														</span>
														<span className={`headline-5 dark-100 ${isRightMenuOpen ? 'body-2' : ''
														}`}>
															{assessSpec.curr_assessment_spec.deadline
																? `In ${assessSpec.curr_assessment_spec.deadline /
															86400
																} Days`
																: 'None'}
														</span>
													</div>
													<div className='AssessmentsStatsIcon-container'>
														<ExpiryStatsIcon className='AssessmentsStatsIcon'/>
													</div>
												</div>
												<div
													className={`assessments-stats-card ${isRightMenuOpen ? 'candidate-assess-stats-card' : ''}`}
													style={isRightMenuOpen ? { width: '22.5%', gap: '1.5em' } : {}}
												>
													<div className='stats-card-text' >
														<span className='body-2  dark-50' style={{ color: '#808191', display: isRightMenuOpen ? 'none' : 'block' }}>
														Invites Sent
														</span>
														<span className={`headline-5 dark-100 ${isRightMenuOpen ? 'body-2' : ''
														}`}>
															{assesment?.invited || 0}
														</span>
													</div>
													<div className='AssessmentsStatsIcon-container'>
														<InvitedStatsIcon className='AssessmentsStatsIcon'/>
													</div>
												</div>
												<div
													className={`assessments-stats-card ${isRightMenuOpen ? 'candidate-assess-stats-card' : ''}`}
													style={isRightMenuOpen ? { width: '22.5%', gap: '1.5em' } : {}}
												>
													<div className='stats-card-text'>
														<span className='body-2  dark-50' style={{ color: '#808191', display: isRightMenuOpen ? 'none' : 'block' }}>
														Attempts
														</span>
														<span className={`headline-5 dark-100 ${isRightMenuOpen ? 'body-2' : ''
														}`}>
															{assesment?.completed || 0}
														</span>
													</div>
													<div className='AssessmentsStatsIcon-container'>
														<AttemptsStatsIcon className='AssessmentsStatsIcon'/>
													</div>
												</div>
											</div>
										</div>
										<div style={{background:'white',borderRadius:'24px'}}>
											{/* <div className='assessment-criteria-heading' style={{padding:'34px 24px 24px 24px'}}>
												<div className='d-flex' style={{gap:'16px'}}>
													<div className={`assessment-criteria-heading-type ${isCandDetails && 'assessment-criteria-heading-type-selected'}`} onClick={() => setIsCandDetails(true)}>
														<span className={`body-2`}>Candidates</span>
													</div>
													<div className={`assessment-criteria-heading-type ${!isCandDetails && 'assessment-criteria-heading-type-selected'}`} onClick={() => setIsCandDetails(false)}>
														<span className={`body-2`}>Criteria</span>
													</div>
												</div>
												<div className='assessments-search-bar' >
													<div className='d-flex' style={{ gap: '0.5em' }}>
														<div className='search' style={{background:'#F6F5F8'}}>
															<Search height={'24px'} width={'24px'} />
															<Input
																inputStyle={{
																	background: 'transparent',
																	border: 'none',
																}}
																className={'w-100'}
																placeholder={'Search ...'}
																onChange={(e) => {
																	setCustomSearch(e);
																}}
															/>
														</div>
													</div>
												</div>
											</div>
											{!isCandDetails ?
												<AssessmentCriteria assessSpec={assessSpec} />
												:
											} */}
											<div className='candidate-details-container'>
												<div className='w-100' style={{height: 'calc(100vh - 325px)',borderRadius:'24px',background:'white'}}>
													<Table
														headers={
															isRightMenuOpen
																? OnOpenRightSideBartestTableHeader
																: testTableHeader
														}
														// data={selectedNavLink === 'all' ? testData : testData.filter((_test) => { return (_test.status?.toLowerCase() === selectedNavLink?.toLowerCase()); })}
														data={testData}
														setData={setTestData}
														onSelectRow={(row) => {
															setIsRightMenuOpen(true);
															setCommonValues({
																...commonValues,
																isSideBarExpanded: false,
															});
															setSelectedRow(row);
														}}
														searchColumns={searchColumns}
														// disableSearchInput={true}
														customSorting={customSorting}
														enableCheckbox={true}
														highlightActiveRow
														testDetails={true}
														customSearch={false}
														handleBulkUpdate={handleBulkUpdate}
														nullTableTitle={'No Assessments to show'}
														nullTableDescription={`Try adjusting your search query or filters`}
														handleRowSelect={handleRowSelect}
														selectedId={selectedRow?.id}
													></Table>
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								<div className={`d-flex flex-column hide-scrollbar`}>
									<MinimizeViewBar />
									{commonValues?.detailedTestView?.isExpanded === false ? (
										<TestDetailsRightView
											onClose={() => {
												setIsRightMenuOpen(false);
											}}
											assessment={selectedRow}
											onUpdateSharedPublicly={onUpdateSharedPublicly}
											onHiringStageUpdate={onHiringStageUpdate}
											assessmentsLength={testData.length}
											selectedIndex={testData.findIndex(
												(test) => test.id === selectedRow?.id
											)}
											onSwitchAssessment={(next = false) => {
												const index = testData.findIndex(
													(test) => test.id === selectedRow?.id
												);
												if (index !== -1) {
													if (next && testData[index + 1]) {
														setSelectedRow(testData[index + 1]);
													} else if (testData[index - 1]) {
														setSelectedRow(testData[index - 1]);
													}
												}
											}}
										/>
									) : (
										<TestDetailedView assessment={selectedRow} />
									)}
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</>
	);
}
