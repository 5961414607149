/* eslint-disable no-constant-condition */
import React, { useEffect, useState, useMemo } from 'react';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/Views/dashboard/assessments/plus.svg';
import { ReactComponent as TickIcon } from '../../../../assets/icons/Views/dashboard/assessments/check.svg';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/Views/dashboard/assessments/chevron-down.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/Views/dashboard/assessments/red_x.svg';
import Checkbox from '../../../../components/sub-component/Checkbox';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomTooltip from '../../../../components/common/CustomTooltip';
import Radio from '../../../../components/sub-component/Radio';
import Input from '../../../../components/sub-component/Input';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/Views/dashboard/assessments/info.svg';
import CreateAssessmentTestTypes from './create-assessment/CreateAssessmentTestTypes';

const CreateAssessmentAdditionalSections = (props) => {
	const isOnWebScreen = useMediaQuery('(min-width:1200px)');
	const isOnTabletScreen = useMediaQuery('(min-width: 768px) and (max-width: 1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');


	const [selectedDeadline, setSelectedDeadline] = useState(undefined);
	const [customDeadlineValue, setCustomDeadlineValue] = useState(null);

	const timeOptions = [
		{ name: 'None', value: null },
		{ name: '1 day', value: 1 * 86400 },         // 1 day = 86400 seconds
		{ name: '2 days', value: 2 * 86400 },       // 2 days = 172800 seconds
		{ name: '3 days', value: 3 * 86400 },       // 3 days = 259200 seconds
		{ name: '5 days', value: 5 * 86400 },       // 5 days = 432000 seconds
		{ name: '1 week', value: 1 * 7 * 86400 },   // 1 week = 7 days = 604800 seconds
		{ name: '2 weeks', value: 2 * 7 * 86400 },  // 2 weeks = 14 days = 1209600 seconds
		{ name: '3 weeks', value: 3 * 7 * 86400 },  // 3 weeks = 21 days = 1814400 seconds
		{ name: '1 month', value: 30 * 86400 },     // 1 month = 30 days = 2592000 seconds
	];


	const { rightSideMenu, welcomeVideoInView, welcomeVideoRef, setSelectedOnRightSideMenuIndex,
		qualifyingQuestionInView, qualifyingQuestionRef, assessmentSectionRef, selectedOnRightSideMenuIndex,
		assessmentSpecsDetails, setAssessmentSpecsDetails, setRightSideMenu, setIsACFBoxExtended, isACFBoxExtended, handleConfirmSection,
		assessmentSectionInView, extendDeadlineBox, setExtendDeadlineBox, deadlineError, setDeadlineError, customDeadline, setCustomDeadline,
		// props for assessment creation test type section
		selectedSectionIndex, setSelectedSectionIndex, selectedSectionType, setSelectedSectionType, errorsInSections,
		setErrorsInSections, getCurrentAssessmentComponentData, getSelectedOnRightSideMenuIndex, onUpdateSections,
		fromDragIndex, setFromDragIndex
	} = props;

	const checkValidation = (deadline, duration) => {
		if (deadline) {
			if (deadline < duration) {
				setDeadlineError('Deadline is less than estimated assessment attempt time! ');
			}
			else {
				setDeadlineError('');
			}
		}
		else {
			if (customDeadline) {
				setDeadlineError('Please specify a Custom deadline');
			}
			else {
				setDeadlineError('');
			}

		}
	};

	useEffect(() => {
		if (selectedDeadline === undefined && customDeadline === false && assessmentSpecsDetails.deadline) {
			if (timeOptions.filter((item, _) => { return (item.value === assessmentSpecsDetails.deadline); }).length > 0) {
				setSelectedDeadline(assessmentSpecsDetails.deadline);
			}
			else {
				setCustomDeadline(true);
				setCustomDeadlineValue(assessmentSpecsDetails.deadline);
			}
		}
	}, []);

	useEffect(() => {
		checkValidation(customDeadline ? customDeadlineValue : assessmentSpecsDetails.deadline, assessmentSpecsDetails.duration);
		if (!extendDeadlineBox && (selectedDeadline || customDeadline)) {
			checkValidation(assessmentSpecsDetails.deadline, assessmentSpecsDetails.duration);
		}
	}, [deadlineError, assessmentSpecsDetails, extendDeadlineBox, customDeadlineValue]);


	const TestTypeMemo = useMemo(() => {
		return (
			<CreateAssessmentTestTypes
				// props for assessment creation test type section
				selectedSectionIndex={selectedSectionIndex}
				setSelectedSectionIndex={setSelectedSectionIndex}
				selectedSectionType={selectedSectionType}
				setSelectedSectionType={setSelectedSectionType}
				errorsInSections={errorsInSections}
				setErrorsInSections={setErrorsInSections}
				getCurrentAssessmentComponentData={getCurrentAssessmentComponentData}
				getSelectedOnRightSideMenuIndex={getSelectedOnRightSideMenuIndex}
				onUpdateSections={onUpdateSections}
				assessmentSpecsDetails={assessmentSpecsDetails}
				fromDragIndex={fromDragIndex}
				setFromDragIndex={setFromDragIndex}
			/>
		);
	}, [selectedSectionIndex, setSelectedSectionIndex, selectedSectionType, setSelectedSectionType, errorsInSections, setErrorsInSections, getCurrentAssessmentComponentData, getSelectedOnRightSideMenuIndex, onUpdateSections, assessmentSpecsDetails, fromDragIndex, setFromDragIndex,]);

	const deadlineMemo = useMemo(() => {


		const _deadline = customDeadlineValue === null ? '' : customDeadlineValue / (60 * 60 * 24);
		return (
			<div className='d-flex flex-wrap'>
				{
					timeOptions.map((option, i) => {
						return (
							<div style={{ width: '50%' }} key={i} >
								<Radio
									varrient="radio-with-custom-component"
									className={'align-items-start test1'}
									style={{ gap: '10px', marginBottom: '10px' }}
									isError={false}
									onChangeRadioOption={() => { setCustomDeadline(false); setSelectedDeadline(option.value); handleConfirmSection({ ...assessmentSpecsDetails, deadline: option.value }); }}
									checked={(option.value === assessmentSpecsDetails.deadline && !customDeadline)}
								>
									<div><span className='body-3 light'>{option.name}</span></div>
								</Radio>
							</div>
						);
					})}
				<div style={{ width: '100%' }}  >

					<Radio
						varrient="radio-with-custom-component"
						className={'align-items-start w-100'}
						style={{ gap: '10px' }}
						isError={(customDeadline && deadlineError)}
						onChangeRadioOption={() => {
							handleConfirmSection({ ...assessmentSpecsDetails, deadline: customDeadlineValue || null });
							setSelectedDeadline(null);
							setCustomDeadline(true);
						}}
						checked={customDeadline}
					>
						<div style={{ gap: '5px' }} className='w-100 d-flex body-3 light align-items-center'
							onClick={() => {
								if (!customDeadline) {
									handleConfirmSection({ ...assessmentSpecsDetails, deadline: customDeadlineValue });
									setCustomDeadline(true);
								}
							}}> Custom:
							<Input
								min={1}
								max={365}
								// type={'number'}
								value={_deadline}
								onChange={(e) => {
									if (e.target.value === '') {
										setCustomDeadlineValue(null);
									}
									else if (!isNaN(e.target.value) && parseInt(e.target.value) >= 0) {
										if (parseInt(e.target.value) > 365) {
											setCustomDeadlineValue(365 * (60 * 60 * 24));
										}
										else {
											setCustomDeadlineValue(parseInt(e.target.value) * (60 * 60 * 24));
										}
									}
								}}
								onBlur={() => { handleConfirmSection({ ...assessmentSpecsDetails, deadline: customDeadlineValue || null }); }}
								inputStyle={{ color: (customDeadline && deadlineError) && '#FC4848' }}
								className={`deadline-input`} /> Days
						</div>
					</Radio>
				</div>
			</div>
		);
	}, [assessmentSpecsDetails, customDeadline, customDeadlineValue, deadlineError]);
	return (
		<div className='create-assessment-right-container '>
			<div className='create-assessment-right-inner-container '>
				{isOnWebScreen &&
					<></>
					// <>
					// 	{/* <span className='body-2 dark-50' >On this page</span> */}
					// 	{
					// 		rightSideMenu.welcome_video &&
					// 		<span
					// 			// className={`${selectedOnRightSideMenuIndex === 0 ? 'focused-assessment-section' : 'black-700'} headline-small semibold pointer`}
					// 			className={`${welcomeVideoInView.inView ? 'focused-assessment-section' : 'dark-50'} body-1 pointer`}
					// 			style={{ marginTop: '16px' }}
					// 			onClick={() => {
					// 				welcomeVideoRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
					// 				setSelectedOnRightSideMenuIndex(0);
					// 			}}

					// 		>Welcome Video</span>
					// 	}
					// 	{
					// 		rightSideMenu.qualifying_questions &&
					// 		<span
					// 			className={`${!welcomeVideoInView.inView && qualifyingQuestionInView.inView ? 'focused-assessment-section' : 'dark-50'} body-1 pointer`}
					// 			style={{ marginTop: '16px' }}
					// 			onClick={() => {
					// 				qualifyingQuestionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
					// 				setSelectedOnRightSideMenuIndex(1);
					// 			}}
					// 		>
					// 			Qualifying Questions
					// 		</span>
					// 	}
					// 	<span
					// 		className={`${!welcomeVideoInView.inView && !qualifyingQuestionInView.inView && assessmentSectionInView.inView ? 'focused-assessment-section' : 'dark-50'} body-1 pointer`}
					// 		style={{ marginTop: '1em' }}
					// 		onClick={() => {
					// 			assessmentSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
					// 			setSelectedOnRightSideMenuIndex(2);
					// 		}}
					// 	>
					// 		My Assessment
					// 	</span>
					// 	{
					// 		rightSideMenu.assessSections.map((item, i) => {
					// 			return (
					// 				<span key={i} className={`body-4 ${(selectedOnRightSideMenuIndex - 2) === i ? 'focused-assessment-section' : ' black-500'} `} style={{ marginTop: '0.75em', paddingLeft: '0.875em', fontSize: '0.75em' }}>{item.name}</span>
					// 			);
					// 		})
					// 	}
					// 	{/* <div className='w-100' style={{ border: '1px solid #DEDAF2', marginTop: '2em' }}></div> */}
					// </>
				}
				{
					isOnWebScreen ?
						TestTypeMemo
						:
						<> </>
				}

				<span className='subtitle-2 dark-100' style={{ marginTop: '24px' }}> Additional Sections</span>
				{/* <span className='body-2 dark-50' style={{ fontWeight: '400', marginTop: '10px' }}> Add more sections to your assessments </span> */}

				<CustomTooltip text={'Click to add'} position='bottom-end'
					width="100%" disabled={(isOnTabletScreen || isOnMobileScreen) ? true : false} customPosition={{
						bottom: '10px',
						left: '10px'
					}}>
					<div className={`additional-section ${assessmentSpecsDetails?.welcome_video && 'selected-additional-section'}`} style={{ marginTop: '16px', flexDirection: 'row' }}
						onClick={() => {
							setAssessmentSpecsDetails({ ...assessmentSpecsDetails, welcome_video: '' });
							setRightSideMenu({ ...rightSideMenu, welcome_video: 'Welcome Video' });
							setSelectedOnRightSideMenuIndex(0);
						}}>
						<span className='body-2 dark-100'>Welcome Video</span>
						{
							assessmentSpecsDetails?.welcome_video !== undefined
								?
								<TickIcon style={{ width: '20px', height: '20px' }} />
								:
								<PlusIcon style={{ width: '20px', height: '20px' }} className="pointer" />
						}
					</div>
				</CustomTooltip>

				<CustomTooltip text={'Click to add'} position='bottom-end'
					width="100%" disabled={(isOnTabletScreen || isOnMobileScreen) ? true : false} customPosition={{
						bottom: '10px',
						left: '10px'
					}}>
					<div className={`additional-section ${assessmentSpecsDetails?.qualifying_questions && 'selected-additional-section'}`} style={{ marginTop: '16px', flexDirection: 'row' }}
						onClick={() => {
							setAssessmentSpecsDetails({ ...assessmentSpecsDetails, qualifying_questions: [] });
							setRightSideMenu({ ...rightSideMenu, qualifying_questions: 'Qualifying Questions' });
							// setSelectedOnRightSideMenuIndex(1);
						}}>
						<span className='body-2 dark-100'>Qualifying Questions</span>
						{
							assessmentSpecsDetails?.qualifying_questions
								?
								<TickIcon style={{ width: '20px', height: '20px' }} />
								:
								<PlusIcon style={{ width: '20px', height: '20px' }} className="pointer" />
						}
					</div>
				</CustomTooltip>
				{/* <span className='body-2 dark-50' style={{ marginTop: '16px' }}>
					Sections will be automatically added in their hierarchy in the final assessment view.
				</span> */}

				<span className='subtitle-2 dark-100' style={{ marginTop: '24px' }}> Additional Settings</span>
				{/* <span className='body-2 dark-50' style={{ fontWeight: '300', marginTop: '10px' }}> Add additional settings to improve your assessment experience </span> */}
				<div className={`additional-section w-100 ${assessmentSpecsDetails?.anti_cheat_settings?.snapshot_settings?.active && 'selected-additional-section'}`} style={{ marginTop: '16px' }}
				>
					<CustomTooltip text={'Click to add'} position='bottom-end'
						width="100%" disabled={(isOnTabletScreen || isOnMobileScreen) ? true : false} customPosition={{
							bottom: '10px',
							left: '10px'
						}}>
						<div className='w-100 d-flex justify-content-between align-items-center' onClick={() => {
							// setAssessmentSpecsDetails({ ...assessmentSpecsDetails, qualifying_questions: [] });
							// setRightSideMenu({ ...rightSideMenu, qualifying_questions: 'Qualifying Questions' });
							// setSelectedOnRightSideMenuIndex(1);
							setIsACFBoxExtended(!isACFBoxExtended);
						}}>
							<span className='body-2 dark-100'>Anti-Cheat Monitor</span>
							{
								assessmentSpecsDetails?.anti_cheat_settings?.snapshot_settings?.active
									?
									<TickIcon style={{ width: '20px', height: '20px' }} />
									:
									<ArrowIcon style={{ width: '20px', height: '20px' }} className="pointer arrow" />
							}</div>
					</CustomTooltip>
					{
						isACFBoxExtended &&
						<div className='additional-section-extended'>
							<div className='d-flex justify-content-start additional-section-extended-details mt-3'>
								<Checkbox primary
									checked={assessmentSpecsDetails?.anti_cheat_settings?.snapshot_settings?.active}
									onChange={() => {
										handleConfirmSection({
											...assessmentSpecsDetails,
											anti_cheat_settings: {
												snapshot_settings: {
													active: assessmentSpecsDetails?.anti_cheat_settings?.snapshot_settings?.active ? false : true,
													required: false
												}
											}
										});
									}}
								/>
								<div className='d-flex'>
									<span className='body-2 dark-100' style={{ textAlign: 'left', fontWeight: '300', marginLeft: '6px' }}>Snapshots of candidates will be taken every 30 seconds via webcam.</span>
								</div>
							</div>
							{
								assessmentSpecsDetails?.anti_cheat_settings?.snapshot_settings?.active &&
								<div className='d-flex additional-section-extended-details'>
									<Checkbox primary
										checked={assessmentSpecsDetails?.anti_cheat_settings?.snapshot_settings?.required}
										onChange={() => {
											handleConfirmSection({
												...assessmentSpecsDetails,
												anti_cheat_settings: {
													snapshot_settings: {
														active: assessmentSpecsDetails?.anti_cheat_settings?.snapshot_settings?.active ? true : false,
														required: assessmentSpecsDetails?.anti_cheat_settings?.snapshot_settings?.required ? false : true
													}
												}
											});
										}}
									/>
									<div className='d-flex'>
										<span className='body-2 dark-100' style={{ textAlign: 'left', fontWeight: '300', marginLeft: '6px' }}>Mark mandatory for candidates to enable webcam while doing assessment.</span>
									</div>
								</div>
							}

						</div>
					}
				</div>



				<CustomTooltip text={'Click to add'} position='bottom-end'
					width="100%" disabled={(isOnTabletScreen || isOnMobileScreen) ? true : false} customPosition={{
						bottom: '10px',
						left: '10px'
					}}>
					<div className={`additional-section ${(deadlineError) ? 'addition-settings-validation-error' : (selectedDeadline || customDeadline) && 'selected-additional-section'}`} style={{ marginTop: '16px' }}
					>
						<div className='w-100 d-flex justify-content-between align-items-center' onClick={() => {
							setExtendDeadlineBox(!extendDeadlineBox);
						}}>
							<span className='body-2 dark-100'>Assessment Deadline</span>
							{
								deadlineError
									?
									<CrossIcon />
									:
									<>
										{
											(selectedDeadline || customDeadline)
												?
												<TickIcon style={{ width: '20px', height: '20px' }} />
												:
												<ArrowIcon style={{ width: '20px', height: '20px' }} className="pointer arrow" />
										}
									</>
							}
						</div>
						{
							extendDeadlineBox &&
							<div className='additional-section-extended mt-3' style={{ borderColor: deadlineError && '#FC4848', background: 'white', borderRadius: '24px', padding: '12px 18px 12px 18px' }}>
								{
									(deadlineError) &&
									<div className='decline d-flex flex-row align-items-start'>
										<span className='body-3 danger-text'>
											{deadlineError}
										</span>
									</div>

								}
								<span className={`body-2 dark-100 ${isOnTabletScreen ? 'd-flex justify-content-between' : ''}`}>Select a deadline for your assessment:
									<CustomTooltip customPosition={{ left: '12px', bottom: '10px', width: '226px' }} text="The assessment will expire after this period, starting from the time a candidate gets invited."  >
										<InfoIcon style={{ height: '20px', width: '20px', marginLeft: '5px' }} />
									</CustomTooltip></span>
								{deadlineMemo}
							</div>
						}
					</div>
				</CustomTooltip>


			</div>
		</div>
	);
};

export default CreateAssessmentAdditionalSections;
